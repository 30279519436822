import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareParking,
  faMugSaucer,
  faShower,
  faCreditCard,
  faVideo,
  faCoffee,
  faParking,
  faBath,
  faToilet,
  faHandHolding,
  faUserShield,
  faShoePrints,
  faPrayingHands,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import config from "../service/api";

const iconMapping = {
  "square-parking": faSquareParking,
  "mug-saucer": faMugSaucer,
  shower: faShower,
  "credit-card": faCreditCard,
  video: faVideo,
  coffee: faCoffee,
  parking: faParking,
  bath: faBath,
  toilet: faToilet,
  "hand-holding": faHandHolding,
  "user-shield": faUserShield,
  "shoe-prints": faShoePrints,
  "hands-praying": faPrayingHands,
};

function Tesislerim() {
  const ApiBaseUrl = config.ApiBaseUrl;
  const ApiBaseUrlImage = config.ApiBaseUrlImage;

  const [facilities, setFacilities] = useState([]);
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userId) {
          const response = await axios.get(
            `${ApiBaseUrl}api/facilities/${userId}`
          );
          setFacilities(response.data);
        }
      } catch (error) {
        console.error("Error fetching facilities:", error);
      }
    };

    fetchData();
  }, [userId]);

  const handleReservationClick = (city, id) => {
    navigate(`/tesislerim/admin/${city}/${id}`);
  };
  const handleReservationGet = (city, id) => {
    navigate(`/admin/tesislerim/${city}/${id}`);
  };

  return (
    <div className="bg pt-5">
      <div className="container">
        <h1 className="text-white text-center">Tesislerim</h1>
        <div className="row">
          {facilities.map((facility, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div className="card h-100">
                <img
                  src={`${ApiBaseUrlImage}${facility.image}`}
                  className="card-img-top"
                  alt="Halı Sahası"
                />
                <div
                  className="card-body d-flex flex-column"
                  style={{ fontFamily: "Arial, sans-serif" }}
                >
                  <h4 className="card-title text-uppercase font-weight-bold">
                    {facility.name}
                  </h4>
                  <h4 className="card-title  ">
                    {facility.description}
                  </h4>
                  <p className="card-text mt-4">
                    {facility.features.map((feature, index) => (
                      <span key={index} className="d-flex align-items-center">
                        •
                        {iconMapping[facility.featuresIcons[index]] && (
                          <FontAwesomeIcon
                            icon={iconMapping[facility.featuresIcons[index]]}
                            style={{ marginLeft: 8, marginRight: 8 }}
                          />
                        )}
                        {feature}
                      </span>
                    ))}
                  </p>
                  <p
                    className="card-text ml-2"
                    style={{ color: "green", fontWeight: "bold" }}
                  >
                    Fiyat: {facility.price} TL
                  </p>
                  <p
                      className="card-text ml-2"
                      style={{ color: "green", fontWeight: "bold" }}
                  >
                    Kapora: {facility.kapora} TL
                  </p>
                  <p className="card-text font-weight-bold ml-2">
                    Adres: {facility.address}
                  </p>

                  <div className="d-flex justify-content-between">
                    <div className="col-md-6 d-flex align-items-stretch">
                      <button
                          type="button"
                          className="btn btn-outline-warning btn-block d-flex align-items-center justify-content-center"
                          onClick={() => handleReservationClick(facility.city, facility.id)}
                      >
                        Rezervasyon Kapat ve Görüntüle
                      </button>
                    </div>
                    <div className="col-md-6 d-flex align-items-stretch">
                      <button
                          type="button"
                          className="btn btn-outline-danger btn-block d-flex align-items-center justify-content-center"
                          onClick={() => handleReservationGet(facility.city, facility.id)}
                      >
                        Tesisi Güncelle
                      </button>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Tesislerim;
