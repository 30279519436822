import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import config from "../service/api";

function Rezervasyonlarım() {
  const ApiBaseUrl = config.ApiBaseUrl;
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    const fetchReservations = async () => {
      const userId = localStorage.getItem("userId");
      try {
        const response = await axios.get(
          `${ApiBaseUrl}api/userReservations/${userId}`
        );
        setReservations(response.data);
      } catch (error) {
        console.error("Rezervasyonlar alınamadı:", error);
      }
    };

    fetchReservations();
  }, []);

  const cancelReservation = async (city, fieldId, date, time) => {
    try {
      const response = await axios.delete(
        `${ApiBaseUrl}api/reservations/${city}/${fieldId}/${date}/${time}`
      );
      if (response.status === 200) {
        setReservations((prevReservations) =>
          prevReservations.filter(
            (reservation) =>
              reservation.time !== time || reservation.date !== date
          )
        );
      } else {
        console.error("Rezervasyon iptali başarısız.");
      }
    } catch (error) {
      console.error("Rezervasyon iptali başarısız:", error);
    }
  };
  const formatDateOneDayAhead = (dateString) => {
    const selectedDate = new Date(dateString);

    return selectedDate.toISOString().split('T')[0];
  };
  return (
    <div className="bg  pb-5">
      <Container sx={{ pt: 4 }}>
        <Card sx={{ boxShadow: 3, mt: 4 }}>
          <CardContent>
            <Typography component="div" variant="h6" sx={{ mb: 2 }}>
              Rezervasyonlarım
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tesis Adı</TableCell>
                    <TableCell>Şehir</TableCell>
                    <TableCell>Tarih</TableCell>
                    <TableCell>Saat</TableCell>
                    <TableCell>Ad</TableCell>
                    <TableCell>Soyad</TableCell>
                    <TableCell>Telefon Numarası</TableCell>
                    <TableCell>Durak Bilgisi</TableCell>
                    <TableCell>Ödeme Durumu</TableCell>

                    <TableCell>İşlemler</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reservations.length > 0 ? (
                    reservations.map((reservation, index) => (
                      <TableRow key={index}>
                        <TableCell>{reservation.fieldName}</TableCell>
                        <TableCell>{reservation.city}</TableCell>
                        <TableCell>{formatDateOneDayAhead(reservation.date)}</TableCell>
                        <TableCell>{reservation.time}</TableCell>
                        <TableCell>{reservation.userName}</TableCell>
                        <TableCell>{reservation.userSurname}</TableCell>
                        <TableCell>{reservation.userPhone}</TableCell>
                        <TableCell>
                          {reservation.serviceStop
                            ? reservation.serviceStop
                            : "Servis hizmeti almadı"}
                        </TableCell>
                        <TableCell>{reservation.payment_status}</TableCell>

                        <TableCell>
                          <IconButton
                            color="error"
                            onClick={() =>
                              cancelReservation(
                                reservation.city,
                                reservation.fieldId,
                                reservation.date,
                                reservation.time
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan="9" align="center">
                        Rezervasyon bulunamadı.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default Rezervasyonlarım;
