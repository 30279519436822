import React from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import HomeIcon from "@mui/icons-material/Home";
import BookIcon from "@mui/icons-material/Book";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoginIcon from "@mui/icons-material/Login";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import logo from "../assets/logoNavbar.png";
import "./NavbarMUI.css";
import { useNavigate } from "react-router-dom";
import {enqueueSnackbar} from "notistack";

const NavigationBar = () => {
  const isLoggedIn = !!localStorage.getItem("token");
  const isAdmin = localStorage.getItem("adminkey") === "12369874";
  const location = useLocation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMobile = useMediaQuery("(max-width:1200px)");
  const isTablet = useMediaQuery("(min-width:1200px) and (max-width:900px)");

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate("/");
    window.location.reload();
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
  };

  const linkStyle = `navbar-link ${
      location.pathname === "/" ? "navbar-link-active" : ""
  }`;

  const handleCreateFacility = () => {
    if (!isLoggedIn) {
      navigate("/login");
    } else if (!isAdmin) {
        console.log("isAdmin",isAdmin)
      navigate("/");
    } else {
    console.log("create facility");

    }
    handleClose();
  };
  const handleCreateFacilityTesis = () => {
    if (!isLoggedIn) {
      navigate("/login"); // Redirect to login page if not logged in
    } else if (isAdmin) {
      navigate("/tesislerim"); // Navigate to "Hemen Tesis Oluştur" page
    } else {
      alert(
          "Yetkili Kullanıcı değilsiniz! Tesis Oluşturmak için Hemen İletişime Geçiniz"
      );
    }
    handleClose();
  };
    const handleLogoClick = () => {

        navigate("/");
    };
    const handleTitleClick = () => {

        navigate("/");
    }
  return (
      <AppBar position="static" style={{ backgroundColor: "white" }}>
          <Toolbar>
              <img
                  src={logo}
                  alt="Logo"
                  className={`navbar-logo ${isTablet ? "navbar-logo-tablet" : ""}`}
                  style={{
                      width: "70px",
                      height: "70px",
                      verticalAlign: "middle",
                      fontFamily: "helvetica",
                      marginLeft: "15px",
                      cursor: "pointer", // İmleci el işareti olarak göstermek için
                  }}
                  onClick={handleLogoClick} // Logo tıklandığında handleLogoClick fonksiyonunu çağır
              />


            <Typography
                variant="h6"
                className={`navbar-title ${isTablet ? "navbar-title-tablet" : ""}`}
                style={{
                    flexGrow: 1,
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 500,
                    fontSize: "24px",
                    color: "#050037",
                    marginLeft: "15px",
                    cursor: "pointer"
                }}
                onClick={handleTitleClick}
            >
                tesisbul.com
            </Typography>

            {!isMobile && (
              <div style={{ display: "flex", gap: "15px" ,color:"#001845"}}>
                <Button
                    component={Link}
                    to="/"
                    startIcon={<HomeIcon />}
                    className={linkStyle}
                >
                  Anasayfa
                </Button>

                <Button
                    component={Link}
                    to="/panel"
                    onClick={handleCreateFacility}
                    startIcon={<AddCircleOutlineIcon />}
                    className={
                      location.pathname === "/panel"
                          ? "navbar-link-active"
                          : "navbar-link"
                    }
                >
                  Hemen Tesis Oluştur
                </Button>
                {isLoggedIn && (
                    <>
                      <Button
                          component={Link}
                          to="/rezervasyonlarim"
                          startIcon={<BookIcon />}
                          className={
                            location.pathname === "/rezervasyonlarim"
                                ? "navbar-link-active"
                                : "navbar-link"
                          }
                      >
                        Rezervasyonlarım
                      </Button>
                      <Button
                          component={Link}
                            to="/tesislerim"
                          onClick={handleCreateFacilityTesis}
                          startIcon={<SportsSoccerIcon />}
                          className={
                            location.pathname === "/tesislerim"
                                ? "navbar-link-active"
                                : "navbar-link"
                          }
                      >
                        Tesislerim
                      </Button>
                    </>
                )}
                <Button
                    component={Link}
                    to="/createplayer"
                    startIcon={<PersonAddIcon />}
                    className={
                      location.pathname === "/createplayer"
                          ? "navbar-link-active"
                          : "navbar-link"
                    }
                >
                  Kiralık Oyuncu Ol
                </Button>

                {isLoggedIn ? (
                    <Button
                        component={Link}
                        onClick={handleLogout}
                        startIcon={<ExitToAppIcon />}
                        className="navbar-link"
                    >
                      Çıkış Yap
                    </Button>
                ) : (
                    <>
                      <Button
                          component={Link}
                          to="/login"
                          startIcon={<LoginIcon />}
                          className={
                            location.pathname === "/login"
                                ? "navbar-link-active"
                                : "navbar-link"
                          }
                      >
                        Giriş Yap
                      </Button>
                      <Button
                          component={Link}
                          to="/register"
                          startIcon={<PersonAddIcon />}
                          className={
                            location.pathname === "/register"
                                ? "navbar-link-active"
                                : "navbar-link"
                          }
                      >
                        Kayıt Ol
                      </Button>
                    </>
                )}
              </div>
          )}

          {isMobile && (
              <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMenu}
                  style={{ color: "#050037" }}
              >
                <MenuIcon />
              </IconButton>
          )}
          <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
          >
            {[
              <MenuItem onClick={handleClose} key="home">
                <Link
                    to="/"
                    className={
                      location.pathname === "/" ? "navbar-link-active" : "navbar-link"
                    }
                >
                  <HomeIcon /> Anasayfa
                </Link>
              </MenuItem>,
              <MenuItem onClick={handleCreateFacility} key="create-facility">
                <div>
                  <AddCircleOutlineIcon /> Hemen Tesis Oluştur
                </div>
              </MenuItem>,
              isLoggedIn && [
                <MenuItem onClick={handleClose} key="reservations">
                  <Link
                      to="/rezervasyonlarim"
                      className={
                        location.pathname === "/rezervasyonlarim"
                            ? "navbar-link-active"
                            : "navbar-link"
                      }
                  >
                    <BookIcon /> Rezervasyonlarım
                  </Link>
                </MenuItem>,
                <MenuItem onClick={handleClose} key="my-facilities">
                  <Link
                      to="/tesislerim"
                      className={
                        location.pathname === "/tesislerim"
                            ? "navbar-link-active"
                            : "navbar-link"
                      }
                  >
                    <SportsSoccerIcon /> Tesislerim
                  </Link>
                </MenuItem>,
              ],
              <MenuItem onClick={handleClose} key="create-player">
                <Link
                    to="/createplayer"
                    className={
                      location.pathname === "/createplayer"
                          ? "navbar-link-active"
                          : "navbar-link"
                    }
                >
                  <PersonAddIcon /> Kiralık Oyuncu Ol
                </Link>
              </MenuItem>,
              isLoggedIn ? (
                  <MenuItem onClick={handleLogout} key="logout">
                    <ExitToAppIcon />
                    Çıkış Yap
                  </MenuItem>
              ) : (
                  [
                    <MenuItem onClick={handleClose} key="login">
                      <Link
                          to="/login"
                          className={
                            location.pathname === "/login"
                                ? "navbar-link-active"
                                : "navbar-link"
                          }
                      >
                        <LoginIcon /> Giriş Yap
                      </Link>
                    </MenuItem>,
                    <MenuItem onClick={handleClose} key="register">
                      <Link
                          to="/register"
                          className={
                            location.pathname === "/register"
                                ? "navbar-link-active"
                                : "navbar-link"
                          }
                      >
                        <PersonAddIcon /> Kayıt Ol
                      </Link>
                    </MenuItem>,
                  ]
              ),
            ]}
          </Menu>
        </Toolbar>
      </AppBar>
  );
};

export default NavigationBar;
