import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareParking,
  faMugSaucer,
  faShower,
  faCreditCard,
  faVideo,
  faCoffee,
  faParking,
  faBath,
  faToilet,
  faHandHolding,
  faUserShield,
  faShoePrints,
  faPrayingHands,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import config from "../service/api";

const iconMapping = {
  "square-parking": faSquareParking,
  "mug-saucer": faMugSaucer,
  shower: faShower,
  "credit-card": faCreditCard,
  video: faVideo,
  coffee: faCoffee,
  parking: faParking,
  bath: faBath,
  toilet: faToilet,
  "hand-holding": faHandHolding,
  "user-shield": faUserShield,
  "shoe-prints": faShoePrints,
  "hands-praying": faPrayingHands,
};

function IncomingReservations() {
  const ApiBaseUrl = config.ApiBaseUrl;
  const ApiBaseUrlImage = config.ApiBaseUrlImage;

  const { city, id } = useParams();
  const [field, setField] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    async function fetchFieldDetails() {
      try {
        const response = await fetch(`${ApiBaseUrl}api/field/${city}/${id}`);
        if (response.ok) {
          const data = await response.json();
          setField(data);
        } else {
          console.error("Saha detayları alınamadı.");
        }
      } catch (error) {
        console.error("Saha detayları alınamadı:", error);
      }
    }

    fetchFieldDetails();
  }, [id, city]);

  useEffect(() => {
    async function fetchReservations() {
      const selectedDateString = selectedDate.toISOString().split("T")[0];
      try {
        const response = await fetch(
          `${ApiBaseUrl}api/reservationsDetails/${city}/${id}/${selectedDateString}`
        );
        if (response.ok) {
          const data = await response.json();
          setReservations(data.reservations || []);
        } else {
          console.error("Rezervasyonlar alınamadı.");
        }
      } catch (error) {
        console.error("Rezervasyonlar alınamadı:", error);
      }
    }

    fetchReservations();
  }, [selectedDate, city, id]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const cancelReservation = async (time) => {
    const selectedDateString = selectedDate.toISOString().split("T")[0];
    try {
      const response = await fetch(
        `${ApiBaseUrl}api/reservations/${city}/${id}/${selectedDateString}/${time}`,
        { method: "DELETE" }
      );
      if (response.ok) {
        setReservations((prevReservations) =>
          prevReservations.filter((reservation) => reservation.time !== time)
        );
      } else {
        console.error("Rezervasyon iptali başarısız.");
      }
    } catch (error) {
      console.error("Rezervasyon iptali başarısız:", error);
    }
  };

  if (!field) return <div>Yükleniyor...</div>;

  return (
    <div className="bg pt-5 pb-5">
      <Container>
        <div className="card mb-4 shadow-sm">
          <div className="row no-gutters">
            <div className="col-md-4">
              <img
                src={`${ApiBaseUrlImage}${field.image}`}
                className="card-img"
                alt="Saha"
              />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">{field.name}</h5>
                <p className="card-text">
                  <strong>Adres:</strong> {field.address}
                </p>
                <p className="card-text">
                  <strong>Özellikler:</strong>
                  {field.features.map((feature, index) => (
                    <span key={index} className="d-flex align-items-center">
                      <FontAwesomeIcon
                        icon={iconMapping[field.featuresIcons[index]]}
                        style={{ marginRight: 8 }}
                      />
                      {feature}
                    </span>
                  ))}
                </p>
                <p className="card-text">
                  <strong>Fiyat:</strong> {field.price} ₺
                </p>
                <p className="card-text">
                  <strong>Kapora:</strong> {field.kapora} ₺
                </p>
                <p className="card-text">
                  <strong>Maksimum Oyuncu:</strong> {field.maxplayer}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Rezervasyon Detayları Tablosu */}
        <Card sx={{ boxShadow: 3 }}>
          <CardContent>
            <Typography component="div" variant="h6" sx={{ mb: 2 }}>
              Rezervasyon Detayları
            </Typography>
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="yyyy/MM/dd"
              className="form-control mb-3"
              style={{ marginBottom: "20px" }}
            />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Saat</TableCell>
                    <TableCell>Ad</TableCell>
                    <TableCell>Soyad</TableCell>
                    <TableCell>Telefon Numarası</TableCell>
                    <TableCell>Fatura Adresi</TableCell>
                    <TableCell>İşlemler</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reservations.length > 0 ? (
                    reservations.map((reservation, index) => (
                      <TableRow key={index}>
                        <TableCell>{reservation.time}</TableCell>
                        <TableCell>{reservation.userName || "admin"}</TableCell>
                        <TableCell>
                          {reservation.userSurname || "admin"}
                        </TableCell>
                        <TableCell>{reservation.userPhone || "-"}</TableCell>
                        <TableCell>
                          {reservation.billingAddress || "-"}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="error"
                            onClick={() => cancelReservation(reservation.time)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan="6" align="center">
                        Seçilen tarih için rezervasyon bulunamadı.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default IncomingReservations;
