import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../service/api";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const url = config.ApiBaseUrl;
  const [reservationDetails, setReservationDetails] = useState(() => {
    const storedReservationDetails = localStorage.getItem("rezervasyonDetails");
    return (
      location.state?.rezervasyonDetails ||
      JSON.parse(storedReservationDetails) ||
      {}
    );
  });

  const [paymentDetails, setPaymentDetails] = useState(() => {
    const storedPaymentDetails = localStorage.getItem("paymentDetails");
    return (
      location.state?.paymentDetails || JSON.parse(storedPaymentDetails) || {}
    );
  });
  console.log("rezervasyonDetails", reservationDetails);
  console.log("paymentDetails", paymentDetails);
  useEffect(() => {
    const makeReservation = async () => {
      try {
        const busStop = reservationDetails.service_stop;
        const userPhone = paymentDetails.user_phone;
        const selectedDate = new Date(reservationDetails.selectedDate);
        const formattedDate = selectedDate.toISOString().split("T")[0];
        const selectedTime = reservationDetails.selectedTime;
        const id = reservationDetails.field_id;
        const city = reservationDetails.city;
        const userName = paymentDetails.user_name;
        const userAddress = paymentDetails.user_address;
        const reservasyonDetails = {
          date: formattedDate,
          time: selectedTime,
          userName: userName,
          userSurname: userName,
          userPhone: userPhone,
          billingAddress: userAddress,
          serviceStop: busStop,
          userId: localStorage.getItem("userId"),
          payment_status: reservationDetails.ödeme_durumu,
        };
        const response = await axios.post(
          `${url}api/reserve/${city}/${id}`,
          reservasyonDetails
        );
        navigate("/rezervasyonlarim");
      } catch (error) {
        console.error(
          "Rezervasyon yapılırken hata oluştu:",
          error.response?.data || error.message
        );
      }
    };

    makeReservation();
  }, [paymentDetails, reservationDetails, url]);

  return (
    <div>
      <h1>Ödeme Başarılı</h1>
    </div>
  );
};

export default PaymentSuccess;
