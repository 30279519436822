import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentFail = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate("/retry-payment");
    };

    return (
        <div>
            <h1>Payment Failed</h1>
            <p>There was an issue with your payment. Please try again.</p>
            <button onClick={handleRedirect}>Retry Payment</button>
        </div>
    );
};

export default PaymentFail;
