import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import trLocale from "date-fns/locale/tr";
import "./fieldDetailAdmin.css"
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper, DialogActions, DialogContent, DialogTitle, Dialog, IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBath,
  faCoffee,
  faCreditCard,
  faHandHolding,
  faMugSaucer,
  faParking,
  faPrayingHands,
  faShoePrints,
  faShower,
  faSquareParking,
  faToilet,
  faUserShield,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "notistack";
import config from "../service/api";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

const iconMapping = {
  "square-parking": faSquareParking,
  "mug-saucer": faMugSaucer,
  shower: faShower,
  "credit-card": faCreditCard,
  video: faVideo,
  coffee: faCoffee,
  parking: faParking,
  bath: faBath,
  toilet: faToilet,
  "hand-holding": faHandHolding,
  "user-shield": faUserShield,
  "shoe-prints": faShoePrints,
  "hands-praying": faPrayingHands,
};

function FieldDetailAdmin() {
  const { enqueueSnackbar } = useSnackbar();
  const [availableTimes, setAvailableTimes] = useState([]);
  const { city, id } = useParams();
  const [field, setField] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [reservedTimeSlots, setReservedTimeSlots] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [reservations, setReservations] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [formValues, setFormValues] = useState({
    userName: "",
    userSurname: "",
    userPhone: "",
    billingAddress: "",
    serviceStop: "",
    payment_status: "BAŞARILI",
  });
  const ApiBaseUrl = config.ApiBaseUrl;
    const ApiBaseUrlImage = config.ApiBaseUrlImage;
      useEffect(() => {
        async function fetchFieldDetails() {
          try {
            const response = await fetch(`${ApiBaseUrl}api/field/${city}/${id}`);
            if (response.ok) {
              const data = await response.json();
              setField(data);
              setAvailableTimes(data.selectedTimes);
              setReservations(data.reservations || {});
            } else {
              console.error("Failed to fetch field details.");
            }
          } catch (error) {
            console.error("Failed to fetch field details:", error);
          }
        }

        fetchFieldDetails();
      }, [id, city]);

      const fetchReservations = async () => {
        try {
          const formattedDate = selectedDate.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
          const response = await fetch(
              `${ApiBaseUrl}api/reservations/${city}/${id}/${formattedDate}`
          );
          if (response.ok) {
            const data = await response.json();
            setReservedTimeSlots(data.reservedTimes);
          } else {
            console.error("Failed to fetch reservations.");
          }
        } catch (error) {
          console.error("Failed to fetch reservations:", error);
        }
      };

      useEffect(() => {
        fetchReservations();
      }, [selectedDate, id, city]);


      const handlePreviousDay = () => {
        const today = new Date();
        const prevDate = new Date(selectedDate);
        prevDate.setDate(prevDate.getDate() - 1);

        if (prevDate >= today) {
          setSelectedDate(prevDate);
          setSelectedTime(null);
        }
      };
      const handleNextDay = () => {
        const nextDate = new Date(selectedDate);
        nextDate.setDate(nextDate.getDate() + 1);

        setSelectedDate(nextDate);
        setSelectedTime(null);
      };

      const handleTimeToggle = (time) => {
        setSelectedTime(time);
        setOpenModal(true);
      };

      const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      };

      const handleSubmit = async () => {
        try {
          const formattedDate = selectedDate.toISOString().split("T")[0];
          const response = await fetch(`${ApiBaseUrl}api/reserve/${city}/${id}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              date: formattedDate,
              time: selectedTime,
              ...formValues,
            }),
          });

          if (response.ok) {
            fetchReservations();
            enqueueSnackbar("Zaman dilimi başarıyla güncellendi!", {
              variant: "success",
            });
            setOpenModal(false);
            setFormValues({
              userName: "",
              userSurname: "",
              userPhone: "",
              billingAddress: "",
              serviceStop: "",
            });
          } else {
            console.error("Zaman dilimi güncellenemedi.");
            enqueueSnackbar("Zaman dilimi güncellenemedi.", { variant: "error" });
          }
        } catch (error) {
          console.error("Zaman dilimi güncellenemedi:", error);
          enqueueSnackbar("Zaman dilimi güncellenemedi.", { variant: "error" });
        }
      };

      if (!field) return <div>Loading...</div>;

      // reservations'ı kullanarak sıralama ve tablo için veri hazırlama
      const sortedReservations = Object.keys(reservations)
          .sort((dateA, dateB) => new Date(dateA) - new Date(dateB))
          .reduce((acc, date) => {
            const times = reservations[date];
            Object.keys(times).forEach((time) => {
              acc.push({
                date,
                time,
                ...times[time],
              });
            });
            return acc;
          }, []);

      // Gün gruplarını oluşturma
      const groupedReservations = [];
      let currentDate = null;

      sortedReservations.forEach((reservation, index) => {
        if (reservation.date !== currentDate) {
          // Yeni bir gün başladığında
          groupedReservations.push({ type: "divider", date: reservation.date });
          currentDate = reservation.date;
        }
        groupedReservations.push(reservation);
      });

      const formatDateOneDayAhead = (dateString) => {
        const selectedDate = new Date(dateString);
        return selectedDate.toISOString().split('T')[0] + ' ' + selectedDate.toLocaleDateString('tr-TR', { weekday: 'long' });
      };
  const cancelReservation = async (city, fieldId, date, time) => {
    try {
      const response = await axios.delete(
          `${ApiBaseUrl}api/reservations/${city}/${fieldId}/${date}/${time}`
      );
      if (response.status === 200) {
        setReservations((prevReservations) =>
            Object.keys(prevReservations).reduce((acc, reservationDate) => {
              const times = prevReservations[reservationDate];
              const filteredTimes = Object.keys(times)
                  .filter((t) => !(reservationDate === date && t === time))
                  .reduce((acc, t) => {
                    acc[t] = times[t];
                    return acc;
                  }, {});
              if (Object.keys(filteredTimes).length > 0) {
                acc[reservationDate] = filteredTimes;
              }
              return acc;
            }, {})
        );
        enqueueSnackbar("Rezervasyon başarıyla silindi.", { variant: "success" });
      }
    } catch (error) {
      console.error("Rezervasyon iptal edilemedi:", error);
      enqueueSnackbar("Rezervasyon iptal edilemedi.", { variant: "error" });
    }
  };

  const filteredReservations = groupedReservations.filter((reservation) => {
    const reservationDate = new Date(reservation.date);
    // Hem tarih aralığı hem de 'time' null değilse ekleriz
    return (
        reservationDate >= new Date(startDate) &&
        reservationDate <= new Date(endDate) &&
        reservation.time // `time` null veya undefined değilse filtreler
    );
  });
  return (
      <div className="bg pt-5 pb-5">
        <div className="container">
          <Card className="mb-4">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <CardMedia
                    component="img"
                    src={`${ApiBaseUrlImage}${field.image}`}
                    alt="Field"
                    style={{ height: "100%", objectFit: "cover" }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {field.name}
                  </Typography>
                  <Typography gutterBottom>
                    <strong>Adres:</strong> {field.address}
                  </Typography>
                  <Typography gutterBottom>
                    <strong>Özellikler:</strong>
                  </Typography>
                  <ul>
                    {field.features.map((feature, index) => (
                        <li key={index} className="d-flex align-items-center">
                          {iconMapping[field.featuresIcons[index]] && (
                              <FontAwesomeIcon
                                  icon={iconMapping[field.featuresIcons[index]]}
                                  style={{ marginLeft: 8, marginRight: 8 }}
                              />
                          )}
                          {feature}
                        </li>
                    ))}
                  </ul>
                  <Typography>
                    <strong>Fiyat:</strong> {field.price} ₺
                  </Typography>
                  <Typography variant="body2" component="p">
                    <strong>Kapora:</strong>
                    {field.kapora > 0 ? `${field.kapora} ₺` :'Kapora ücreti alınmamaktadır'}
                  </Typography>
                  <Typography>
                    <strong>Maksimum Oyuncu Sayısı:</strong> {field.maxplayer}
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>

          <Card className="mt-4 mb-5">
            <CardContent>
              <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
                Rezervasyon Yönetimi
              </Typography>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <ArrowCircleLeftIcon
                    onClick={handlePreviousDay}
                    sx={{
                      fontSize: 40,
                      cursor: "pointer",
                    }}
                />
                <DatePicker
                    selected={selectedDate}
                    dateFormat="dd MMMM yyyy EEEEEEEEE"
                    locale={trLocale}
                    className="form-control"
                    customInput={<TextField />}
                />
                <ArrowCircleRightIcon
                    onClick={handleNextDay}
                    sx={{
                      fontSize: 40,
                      cursor: "pointer",
                    }}
                />
              </div>
              <Grid container spacing={2}>
                {availableTimes?.map((time, index) => (
                    <Grid item key={index} xs={6} sm={3}>
                      <Button
                          variant="outlined"
                          color={
                            reservedTimeSlots.includes(time) ? "secondary" : "primary"
                          }
                          fullWidth
                          onClick={() => handleTimeToggle(time)}
                          style={{ marginBottom: 8, marginTop: 16 }}
                          disabled={reservedTimeSlots.includes(time)}
                      >
                        {time}
                      </Button>
                    </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>

          <Dialog open={openModal} onClose={() => setOpenModal(false)}>
            <DialogTitle>Rezervasyon Bilgileri</DialogTitle>
            <DialogContent>
              <TextField
                  label="Ad"
                  name="userName"
                  value={formValues.userName}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
              />
              <TextField
                  label="Soyad"
                  name="userSurname"
                  value={formValues.userSurname}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
              />
              <TextField
                  label="Telefon"
                  name="userPhone"
                  value={formValues.userPhone}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
              />
              <TextField
                  label="Fatura Adresi"
                  name="billingAddress"
                  value={formValues.billingAddress}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
              />
              <TextField
                  label="Servis Durağı"
                  name="serviceStop"
                  value={formValues.serviceStop}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenModal(false)} color="secondary">
                İptal
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Kaydet
              </Button>
            </DialogActions>
          </Dialog>

          <Card className="mt-4 mb-5">
            <CardContent>
              <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
                Rezervasyon Listesi
              </Typography>


              <div style={{ display: "flex", justifyContent: "center"}}>
                <div className="form-group mx-2">
                  <label className="pr-2">Başlangıç Tarihi:</label>
                  <DatePicker
                      className="form-control"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Başlangıç Tarihi Seç"
                      calendarClassName="custom-calendar"
                  />
                </div>

                <div className="form-group mx-2">
                  <label className="pr-2">Bitiş Tarihi:</label>
                  <DatePicker
                      className="form-control"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Bitiş Tarihi Seç"
                      calendarClassName="custom-calendar"
                  />
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Tarih</TableCell>
                      <TableCell>Saat</TableCell>
                      <TableCell>İsim</TableCell>
                      <TableCell>Telefon</TableCell>
                      <TableCell>Adres</TableCell>
                      <TableCell>Servis Durağı</TableCell>
                      <TableCell>Ödeme Durumu</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredReservations.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{formatDateOneDayAhead(item.date)}</TableCell>
                          <TableCell>{item.time}</TableCell>
                          <TableCell>{item.userName}</TableCell>
                          <TableCell>{item.userPhone}</TableCell>
                          <TableCell>{item.billingAddress}</TableCell>
                          <TableCell>
                            {item.serviceStop ? item.serviceStop : "Servis Hizmeti Alınmadı"}
                          </TableCell>
                          <TableCell>{item.payment_status}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => cancelReservation(city, id, item.date, item.time)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </div>
      </div>
  );
}

export default FieldDetailAdmin;
