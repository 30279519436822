import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";

function KullaniciSozlesmesi() {
  return (
      <div className="pt-5 pb-5 bg">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} className="bg-white p-4 border rounded">
              <h2 className="text-center">Tesisbul Kullanıcı Sözleşmesi</h2>

              <p>
                Bu Kullanıcı Sözleşmesi (“Sözleşme”), Tesisbul (bundan böyle “Site” olarak anılacaktır) aracılığıyla sunulan hizmetlerin kullanımına ilişkin şart ve koşulları belirler. Site'ye erişen veya Site'yi kullanan herkes, bu Sözleşme'yi kabul etmiş sayılır.
              </p>

              <h3>1. Taraflar</h3>
              <ul>
                <li>
                  <strong>Tesisbul:</strong> Site'nin ve şirketin sahibi ve işletmecisi olan iki ortak tarafından yönetilmektedir. Tesisbul, kullanıcıların futbol sahaları için online rezervasyon yapmalarını sağlayan bir hizmet sunar.
                </li>
                <li>
                  <strong>Kullanıcı:</strong> Site'ye erişen, Site'de rezervasyon yapan, Site’de kiralık oyuncu olan veya Site'yi herhangi bir şekilde kullanan kişiler.
                </li>
                <li>
                  <strong>Tesis Sahibi:</strong> Site aracılığıyla tesislerini rezerve edilebilir hale getiren kişiler veya işletmeler.
                </li>
              </ul>

              <h3>2. Hizmetler</h3>
              <ul>
                <li>Tesisbul, kullanıcıların çevrimiçi olarak spor tesisleri için rezervasyon yapmasına olanak tanır.</li>
                <li>Tesisbul, kullanıcılarla tesis sahiplerini bir araya getirir ve yalnızca rezervasyon işlemlerine aracılık eder.</li>
              </ul>

              <h3>3. Tesisbul Kullanıcı Yükümlülükleri, Davranışları ve Sorumlulukları</h3>
              <h4>3.1. Kullanıcı Yükümlülükleri</h4>
              <ul>
                <li>Kullanıcılar, Site'ye kaydolurken doğru ve eksiksiz bilgi vermekle yükümlüdür.</li>
                <li>Kullanıcılar, rezervasyon işlemleri sırasında öngörülen kurallara uymak zorundadır.</li>
                <li>Kullanıcılar, rezervasyon yaptıkları tesise zamanında gitmekle ve tesis kurallarına uymakla sorumludur.</li>
              </ul>

              <h4>3.2. Kullanıcı Davranışları</h4>
              <p>
                Tesisbul, kullanıcıların spor tesisleri içerisinde veya dışında sergiledikleri davranışlardan sorumlu değildir. Kullanıcıların tesislerde veya çevresinde gerçekleştirdiği her türlü eylem, tamamen kendilerinin sorumluluğundadır.
              </p>

              <h4>3.3. Tesis İçerisindeki Olaylar</h4>
              <p>
                Tesisbul, tesis içerisinde kullanıcılar arasında meydana gelebilecek tartışma, kavga, sözlü münakaşa ve benzeri olaylardan sorumlu değildir. Bu tür durumlar, tesis yönetimi ve kullanıcılar arasında çözülmelidir.
              </p>

              <h4>3.4. Kullanıcı Güvenliği</h4>
              <p>
                Tesisbul, kullanıcıların tesislerdeki güvenliğini sağlama yükümlülüğünü üstlenmemektedir. Tesisin güvenliği ve kullanıcıların korunması, tesis sahibi ve kullanıcıların sorumluluğundadır. Tesisbul sadece randevu hizmeti sağlamakta olup başka bir durumla ilgilenmemektedir ve sorumluluğu dahilinde değildir.
              </p>

              <h4>3.5. Zarar ve Ziyan</h4>
              <p>
                Tesisbul, kullanıcıların tesislere veya diğer kullanıcılara verebileceği maddi veya manevi zararlardan sorumlu değildir. Kullanıcılar, tesislerde gerçekleştirdikleri faaliyetler sonucunda doğabilecek zararları kendileri karşılamakla yükümlüdür.
              </p>

              <h4>3.6. İletişim ve Şikayetler</h4>
              <p>
                Kullanıcılar arasındaki anlaşmazlıklar veya olumsuzluklarla ilgili şikayetler, doğrudan tesis yönetimine iletilmelidir. Tesisbul, bu tür durumlarda arabulucu veya çözüm sağlayıcı bir rol üstlenmez.
              </p>

              <h4>3.7. Hukuki ve Cezai Sorumluluk</h4>
              <p>
                Tesisbul, kullanıcıların tesis içinde veya dışında gerçekleştirdiği eylemler nedeniyle ortaya çıkabilecek hukuki ve cezai sorumluluklardan muaftır. Her türlü yasal sorumluluk ve yükümlülük, ilgili kullanıcıların kendisine aittir.
              </p>

              <h3>4. Tesisbul’un Sorumlulukları ve Yükümlülükleri</h3>
              <ul>
                <li>Tesisbul.com, yalnızca spor tesislerine yönelik rezervasyon hizmeti sunmakla yükümlüdür.</li>
                <li>Tesisbul.com, kullanıcılar tarafından yapılan kapora ödemeleri PayTR aracılığıyla güvenli şekilde gerçekleştirir.</li>
                <li>Tesisbul.com, spor tesislerinde rezervasyon işlemi dışında meydana gelebilecek herhangi bir olumsuzluktan sorumlu değildir.</li>
                <li>Tesisbul.com, kullanıcıların kişisel verilerini 6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) ve ilgili mevzuat hükümlerine uygun olarak korumak ve işlemekle yükümlüdür. Bu verilerin güvenliği en üst düzeyde sağlanacaktır.</li>
                <li>Tesisbul, rezervasyon oluşturan kullanıcılardan Tesis sahipleri adına kapora ücreti almakla yükümlüdür.</li>
                <li>Tesisbul, kullanıcıların ve tesis sahiplerinin sağladığı bilgilerin doğruluğunu garanti etmez.</li>
                <li>Tesisbul, kullanıcıların rezervasyon süreçlerini izleyebileceği ve yönetebileceği bir arayüz sağlayacaktır.</li>
              </ul>

              <h4>4.1. Hukuki ve Cezai Sorumluluk</h4>
              <p>
                Tesisbul, Tesis sahipleri ve kullanıcılar arasında meydana gelebilecek hukuki ve cezai sorumluluklar konusunda hiçbir yükümlülük taşımamaktadır. Her türlü yasal sorumluluk ve yükümlülük, ilgili tarafların kendisine aittir.
              </p>

              <h3>5. Tesisbul kullanıcılarının tesis ödemeleri ve Ücretlendirme Sorunları</h3>
              <h4>5.1. Kapora ve Geri Kalan Ücret</h4>
              <p>
                Tesisbul, kullanıcıların yalnızca rezervasyon esnasında kapora ücretini tahsil eder. Kapora ücreti dışında kalan tutarın tesis sahibi ile kullanıcı arasında doğrudan ödenmesi gerekmektedir.
              </p>

              <h4>5.2. Ödeme Uyuşmazlıkları</h4>
              <p>
                Tesisbul, kullanıcıların kapora dışında kalan ücretleri tesis sahibine ödememesi durumunda ortaya çıkabilecek uyuşmazlıklardan sorumlu değildir. Bu tür ödeme sorunları, tamamen tesis sahibi ve kullanıcı arasında çözülmelidir.
              </p>

              <h4>5.3. Ödeme Sorumluluğu</h4>
              <p>
                Kullanıcı, Tesisbul üzerinden yaptığı rezervasyonun tamamlanabilmesi için tesis sahibi tarafından belirlenen tüm ücretleri ödemekle yükümlüdür. Tesisbul, kullanıcının ödeme yükümlülüğünü yerine getirmemesi halinde herhangi bir sorumluluk üstlenmez.
              </p>

              <h4>5.4. Hukuki ve Cezai Sorumluluk</h4>
              <p>
                Tesisbul, kapora ödemesi haricindeki ücretlerin ödenmemesi durumunda doğabilecek hukuki ve cezai sorumluluklardan muaftır. Bu tür durumlarda yasal hak ve yükümlülükler, tesis sahibi ve kullanıcı arasında belirlenir.
              </p>

              <h4>5.5. Anlaşmazlık Durumunda</h4>
              <p>
                Kapora ödemesi dışında kalan ücretlerin ödenmemesi veya ödemede yaşanan herhangi bir sorun nedeniyle ortaya çıkabilecek anlaşmazlıklar, Tesisbul'un sorumluluğunda değildir. Tesis sahipleri, bu tür durumlarda kullanıcılarla doğrudan iletişime geçerek sorunu çözmelidir.
              </p>

              <h4>5.6. İade Politikası</h4>
              <p>
                Kapora ücretinin iadesi yoktur. Ancak, kapora haricindeki ödemelerin iadesi konusunda tesis sahibi ve kullanıcı arasında yapılan anlaşmalar geçerlidir. Tesisbul, bu tür iade süreçlerinde taraf değildir.
              </p>

              <h4>5.7. Kullanıcı Bilgilendirmesi</h4>
              <p>
                Kullanıcılar, rezervasyon işlemi sırasında tesis sahibi tarafından belirlenen ücretlendirme ve ödeme koşullarını kabul etmiş sayılır. Tesisbul, kullanıcıların bu bilgilere erişimini sağlamakla yükümlüdür.
              </p>

              <h3>6. Kullanıcı Bilgileri ve Gizlilik Politikası</h3>
              <p>
                Tesisbul, kullanıcıların kişisel bilgilerini gizli tutmakta ve üçüncü kişilerle paylaşmamaktadır. Ancak, kullanıcıların rezervasyon işlemleri için gerekli bilgiler, tesis sahipleriyle paylaşılabilir.
              </p>

              <h3>7. Sözleşmenin Geçerliliği ve Feshi</h3>
              <ul>
                <li>Bu Sözleşme, kullanıcıların Site'ye eriştiği andan itibaren geçerlidir.</li>
                <li>Tesisbul, kullanıcıların bu Sözleşme'ye aykırı davranışlarını tespit ettiğinde, kullanıcıların Site'ye erişimini engelleyebilir.</li>
                <li>Kullanıcılar, Site'yi kullanmayı bıraktıklarında bu Sözleşme sona ermez.</li>
                <li>Tesisbul, Sözleşme'yi tek taraflı olarak değiştirme hakkını saklı tutar. Değişiklikler, Site'de yayınlandığı andan itibaren geçerli sayılır.</li>
              </ul>

              <h3>8. İhtilafların Çözümü</h3>
              <p>
                Bu Sözleşme'den doğabilecek ihtilafların çözümünde İstanbul Mahkemeleri ve İcra Daireleri yetkilidir.
              </p>

              <h3>9. Yürürlük</h3>
              <p>
                Bu Sözleşme, kullanıcıların Site'ye eriştiği tarihten itibaren yürürlüğe girer. Kullanıcılar, Site'yi kullanarak bu Sözleşme'yi kabul etmiş sayılır.
              </p>
              <h3>10. İletişim Bilgileri</h3>
              <p>
                İşbu Sözleşme ile ilgili her türlü soru ve talepleriniz için
                bizimle iletişime geçebilirsiniz:
              </p>
              <ul>
                <li>E-posta: info@tesisbul.com</li>
                <li>Telefon: 0553 174 82 04 – 0505 061 96 88</li>
                <li>Adres: Körfez, Yeşil Pelit Cd., 55270 Atakum/Samsun</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
  );
}

export default KullaniciSozlesmesi;
