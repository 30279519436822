import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const Odeme = () => {
  const location = useLocation();
  const paymentDetails = location.state?.paymentDetails;
  const [iframeToken, setIframeToken] = useState(null);
  const rezervasyonDetails = location.state?.rezervasyonDetails;
  useEffect(() => {
    if (rezervasyonDetails) {
      rezervasyonDetails.ödeme_durumu = "BAŞARILI";
      localStorage.setItem(
        "rezervasyonDetails",
        JSON.stringify(rezervasyonDetails)
      );
      localStorage.setItem("paymentDetails", JSON.stringify(paymentDetails));
    }
  }, [rezervasyonDetails]);

  useEffect(() => {
    if (!paymentDetails) {
      console.error("No payment details provided");
      return;
    }

    const createPayment = async () => {
      try {
        const response = await axios.post(
          "https://btesisbul.com.tr/api/payment/create-payment",
          {
            email: paymentDetails.email,
            payment_amount: paymentDetails.payment_amount,
            user_name: paymentDetails.user_name,
            user_address: paymentDetails.user_address,
            user_phone: paymentDetails.user_phone,
            user_ip: "192.168.1.1",
          }
        );

        setIframeToken(response.data.iframeToken);
      } catch (error) {
        console.error("Payment creation error:", error);
      }
    };

    createPayment();
  }, [paymentDetails]);

  return (
    <>
      {iframeToken ? (
        <iframe
          src={`https://www.paytr.com/odeme/guvenli/${iframeToken}`}
          id="paytriframe"
          title="Payment Processing"
          frameBorder="0"
          scrolling="yes"
          style={{ width: "100%", height: "600px" }}
        ></iframe>
      ) : (
        <div>
          <p>Loading payment information...</p>
        </div>
      )}
    </>
  );
};

export default Odeme;
