import React from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Link,
  TextField,
  Button,
} from "@mui/material";

function Iletisim() {
  return (
    <div className="bg pt-5 pb-5">
      <Container style={{ marginTop: "24px" }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <Paper style={{ padding: "24px", backgroundColor: "white" }}>
              <Typography variant="h4" align="center" gutterBottom>
                İletişim
              </Typography>

              <Typography variant="h5" gutterBottom>
                Ticari Bilgiler
              </Typography>
              <Typography>
                <strong>Ticaret Unvanı:</strong> Tesisbul.com A.Ş.
              </Typography>
              <Typography>
                <strong>Merkez Adresi:</strong> Körfez, Yeşil Pelit Cd., 55270 Atakum/Samsun
              </Typography>

              <Typography
                variant="h5"
                gutterBottom
                style={{ marginTop: "24px" }}
              >
                İletişim Bilgileri
              </Typography>
              <Typography>
                <strong>KEP Adresi:</strong> tesisbul@kep.tr
              </Typography>
              <Typography>
                <strong>Elektronik Posta Adresi:</strong> info@tesisbul.com
              </Typography>
              <Typography>
                <strong>Telefon Numarası:</strong> 0553 174 82 04 – 0505 061 96 88
              </Typography>
              <Typography>
                <strong>İşletme Adı:</strong> Tesisbul.com
              </Typography>


              <Typography
                variant="h5"
                gutterBottom
                style={{ marginTop: "24px" }}
              >
                Meslek Odası ve Davranış Kuralları
              </Typography>
              <Typography>
                <strong>Mensubu Olduğumuz Meslek Odası:</strong> Erzurum
                Ticaret Odası
              </Typography>
              <Typography>
                <strong>Meslekle İlgili Davranış Kuralları:</strong> Meslek
                odası tarafından belirlenen tüm kurallara uyulmaktadır.
              </Typography>
              <Typography>
                <strong>Elektronik Olarak Erişim:</strong>
                <Link
                  href="https://www.erzurumtso.org.tr/"
                  target="_blank"
                  rel="noopener"
                >
                  Erzurum Ticaret Odası Web Sitesi
                </Link>
              </Typography>

              <Typography
                variant="h5"
                gutterBottom
                style={{ marginTop: "24px" }}
              >
                İletişim Formu
              </Typography>
              <form noValidate autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="name"
                      label="Adınız"
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="E-posta Adresiniz"
                      name="email"
                      type="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="subject"
                      label="Konu"
                      name="subject"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="message"
                      label="Mesajınız"
                      name="message"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" type="submit">
                      Gönder
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Iletisim;
