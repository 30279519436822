import React from "react";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Panel from "./pages/panel";
import FieldDetail from "./pages/FieldDetail";
import LoginPage from "./pages/loginPage";
import RegisterPage from "./pages/RegisterPage";
import Tesislerim from "./pages/Tesislerim";
import FieldDetailAdmin from "./pages/FieldDetailAdmin";
import CreatePlayer from "./pages/CreatePlayer";
import {SnackbarProvider, useSnackbar} from "notistack";
import IncomingReservations from "./pages/IncomingReservations.js";
import PlayersList from "./pages/GetPlayers";
import NavigationBar from "./pages/Navbar.js";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Rezervasyonlarım from "./pages/Rezervasyonlarim.js";
import Footer from "./pages/Footer.js";
import UserRegister from "./pages/UserRegister.js";
import GizlilikSozlesme from "./pages/sozlesmeler/GizlilikSozlesme.js";
import KullaniciSozlesmesi from "./pages/sozlesmeler/KullaniciSozlesmesi.js";
import TeslimatIadeSartlari from "./pages/sozlesmeler/TeslimatIadeSartlari.js";
import Iletisim from "./pages/Iletisim.js";
import Hakkimizda from "./pages/Hakkimizda.js";
import TesisUpdate from "./pages/TesisUpdate";
import Payment from "./pages/Payment";
import Odeme from "./pages/Odeme";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFail from "./pages/PaymentFail";
import ReservationsInformations from "./pages/ReservationsInformations";
import {Box, Slide} from "@mui/material";
import {WhatsApp} from "@mui/icons-material";

function ProtectedPanel() {
    const { enqueueSnackbar } = useSnackbar();
    const isLoggedIn = !!localStorage.getItem("token");
    const isAdmin = localStorage.getItem("adminkey") === "12369874";

    if (!isLoggedIn || !isAdmin) {
        enqueueSnackbar("Yetkisiz erişim. Yönetim paneline erişmek için yetkili olmalısınız.", {
            variant: "warning",
        });

        return <Navigate to="/" />;
    }

    return <Panel />;
}

function App() {
    const isLoggedIn = localStorage.getItem("token") !== null;
    const isAdmin = localStorage.getItem("adminKey") === "12369874";

    return (
        <Router>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <NavigationBar />

                <div className="pages">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route
                            path="/admin/rezervasyonlar/:city/:id"
                            element={<IncomingReservations />}
                        />
                        <Route path="/rezervasyonlarim" element={<Rezervasyonlarım />} />
                        <Route path="/admin/tesislerim/:city/:id" element={<TesisUpdate />} />
                        <Route
                            path="/panel"
                            element={isAdmin ? <Panel /> : <ProtectedPanel/>}
                        />
                        <Route path="/field/:city/:id" element={<FieldDetail />} />
                        <Route
                            path="/tesislerim/admin/:city/:id"
                            element={<FieldDetailAdmin />}
                        />
                        <Route
                            path="/login"
                            element={!isLoggedIn ? <LoginPage /> : <Navigate to="/panel" />}
                        />
                        <Route
                            path="/register"
                            element={
                                !isLoggedIn ? <RegisterPage /> : <Navigate to="/panel" />
                            }
                        />
                        <Route path="*" element={<h1>Dostum Böyle Bir Sayfa Yok</h1>} />
                        <Route path="/tesislerim" element={<Tesislerim />} />
                        <Route path="/createplayer" element={<CreatePlayer />} />
                        <Route path="/getplayers" element={<PlayersList />} />
                        <Route path="/user-register" element={<UserRegister />} />
                        <Route path="/gizlilik-sozlesmesi" element={<GizlilikSozlesme />} />
                        <Route
                            path="/kullanici-sozlesmesi"
                            element={<KullaniciSozlesmesi />}
                        />
                        <Route
                            path="/teslimat-iade-sartlari"
                            element={<TeslimatIadeSartlari />}
                        />
                        <Route path="/hakkimizda" element={<Hakkimizda />} />
                        <Route path="/iletisim" element={<Iletisim />} />
                        <Route path="/payment" element={<Payment />} />
                        <Route path="/payment/odeme" element={<Odeme />} />
                        <Route path="/payment-success" element={<PaymentSuccess />} />
                        <Route path="/payment-fail" element={<PaymentFail />} />
                        <Route path="/reservasyonbilgileri" element={<ReservationsInformations />} />
                    </Routes>
                </div>
            </SnackbarProvider>
            <Box sx={{
                position: "fixed",
                bottom: 0,
                right: 16,
                zIndex: 99999,
            }}>
                <Slide direction="up" in={true} timeout={700}>
                    <Box
                        aria-label={"whatsapp"}
                        component={"a"}
                        target="_blank"
                        href="https://api.whatsapp.com/send/?phone=5531748204&text=Merhaba, +Size+www.tesisbul.com+adresinden+ula%C5%9F%C4%B1yorum&type=phone_number&app_absent=0"
                        sx={{
                            background: "#25D366",
                            color: "white",
                            paddingX: 4,
                            display: "flex",
                            cursor: "pointer",
                            py: .25,
                            borderTopLeftRadius: 12,
                            borderTopRightRadius: 12,
                            ":hover": {
                                background: "white",
                                color: "#25D366",
                                boxShadow: "0px 0px 15px 5px rgba(37, 211, 102,0.3);"
                            }
                        }}>
                        <WhatsApp sx={{fontSize: "42px",}}/>
                    </Box>
                </Slide>
            </Box>
            <Footer />
        </Router>
    );
}

export default App;
