import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { amber } from "@mui/material/colors"; // warning rengi için

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem("userId");
  const tesisInformations = location.state.tesisInformations;
  console.log(tesisInformations);
  const telefon = localStorage.getItem("phoneNumber");
  const [paymentDetails, setPaymentDetails] = useState({
    email: tesisInformations.paymentRequired ? "" : "null@null.com",
    payment_amount: tesisInformations.kapora * 100,
    user_name: "",
    user_address: "",
    user_phone: telefon,
    user_ip: "192.168.1.1",
    merchant_oid: new Date().getTime() + userId,
  });

  const [rezervasyonDetails, setRezervasyonDetails] = useState({
    city: tesisInformations.city,
    field_id: tesisInformations.id,
    paymentRequired: tesisInformations.paymentRequired,
    selectedDate: tesisInformations.selectedDate,
    selectedTime: tesisInformations.selectedTime,
    servisDurakları: tesisInformations.serviceStops,
    service_stop: "",
    ödeme_durumu: "Beklemede",
  });
  const [isServiceSelected, setIsServiceSelected] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleInputChangeReserve = (e) => {
    const { name, value } = e.target;
    setRezervasyonDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleServiceSelection = (e) => {
    setIsServiceSelected(e.target.checked);
  };

  const handlePayment = async () => {
    // E-posta kontrolü
    if (tesisInformations.paymentRequired && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(paymentDetails.email)) {
      setEmailError("Geçerli bir e-posta adresi giriniz.");
      return;
    }
    if (tesisInformations.paymentRequired === false) {
      navigate("/payment-success", {
        state: { rezervasyonDetails, paymentDetails },
      });
    } else {
      navigate("/payment/odeme", {
        state: { paymentDetails, rezervasyonDetails },
      });
    }
  };

  useEffect(() => {
    localStorage.removeItem("rezervasyonDetails");
    localStorage.removeItem("paymentDetails");
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="bg pt-5 pb-5">
        <Container component="main" maxWidth="sm">
          <Paper elevation={3} style={{ padding: "2rem" }}>
            <Typography variant="h4" component="h1" gutterBottom>
              {tesisInformations.paymentRequired ? "Ödeme Sayfası" : "Rezervasyon Sayfası"}
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                  variant="h6"
                  component="h1"
                  gutterBottom
                  sx={{ color: amber[700] }}
              >
                Rezervasyon Bilgilerinizi Görene Kadar Lütfen Sayfadan Ayrılmayın.
              </Typography>
            </Box>
            <Box component="form" noValidate autoComplete="off">
                  <>
                    {tesisInformations.paymentRequired && (
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        value={paymentDetails.email}
                        onChange={handleInputChange}
                        error={!!emailError}
                        helperText={emailError}
                    />
                        )}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="user_name"
                        label="İsim Soyisim"
                        name="user_name"
                        value={paymentDetails.user_name}
                        onChange={handleInputChange}
                    />
                    {tesisInformations.paymentRequired && (
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="user_address"
                        label="Adres"
                        name="user_address"
                        value={paymentDetails.user_address}
                        onChange={handleInputChange}
                    />
                        )}
                  </>


              {tesisInformations.servisDuraklari &&
              tesisInformations.servisDuraklari.length > 0 ? (
                  <>
                    <FormControlLabel
                        control={
                          <Checkbox
                              checked={isServiceSelected}
                              onChange={handleServiceSelection}
                          />
                        }
                        label="Servis hizmeti ister misiniz?"
                    />
                    <Typography variant="h6" component="h1" gutterBottom>
                      Servis Ücreti: {tesisInformations.servisFiyat} TL
                    </Typography>
                    {isServiceSelected && (
                        <FormControl fullWidth margin="normal" required>
                          <InputLabel id="service-stop-label">
                            Hizmet Durağı Seçin
                          </InputLabel>
                          <Select
                              label="Hizmet Durağı Seçin"
                              labelId="service-stop-label"
                              id="service_stop"
                              name="service_stop"
                              value={rezervasyonDetails.service_stop}
                              onChange={handleInputChangeReserve}
                          >
                            {tesisInformations.servisDuraklari.map((stop, index) => (
                                <MenuItem key={index} value={stop}>
                                  {stop}
                                </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                    )}
                  </>
              ) : (
                  <Typography variant="h6" component="h1" gutterBottom>
                    Servis hizmeti bulunmamaktadır.
                  </Typography>
              )}
              {tesisInformations.paymentRequired && (
                  <Typography variant="h6" component="h1" gutterBottom>
                    Kapora Tutarı: {tesisInformations.kapora} TL
                  </Typography>
              )}

              {tesisInformations.paymentRequired && (
                  <Typography
                      variant="h6"
                      component="h1"
                      gutterBottom
                      style={{ color: "red" }}
                  >
                    İade İşlemlerinizi 36 saat öncesinden yapabilirsiniz. İade
                    işlemleri için
                    <a href="https://web.whatsapp.com/send?phone=905531748204">
                      {" "}
                      WhatsApp
                    </a>{" "}
                    üzerinden bizimle iletişime geçebilirsiniz.
                  </Typography>
              )}
              <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handlePayment}
                  style={{ marginTop: "1rem" }}
              >
                {tesisInformations.paymentRequired ? "Ödeme Sayfası" : "Rezervasyonu Onayla"}
              </Button>
            </Box>
          </Paper>
        </Container>
      </div>
  );
};

export default PaymentPage;
