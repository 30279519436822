import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import trLocale from "date-fns/locale/tr";

import {
  faSquareParking,
  faMugSaucer,
  faShower,
  faCreditCard,
  faVideo,
  faCoffee,
  faParking,
  faBath,
  faToilet,
  faHandHolding,
  faUserShield,
  faShoePrints,
  faPrayingHands,
} from "@fortawesome/free-solid-svg-icons";
import config from "../service/api";
import { Comments } from "./Comments";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardMedia, TextField } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

const iconMapping = {
  "square-parking": faSquareParking,
  "mug-saucer": faMugSaucer,
  shower: faShower,
  "credit-card": faCreditCard,
  video: faVideo,
  coffee: faCoffee,
  parking: faParking,
  bath: faBath,
  toilet: faToilet,
  "hand-holding": faHandHolding,
  "user-shield": faUserShield,
  "shoe-prints": faShoePrints,
  "hands-praying": faPrayingHands,
};
const useStyles = makeStyles((theme) => ({
  timeSlot: {
    border: "1px solid",
    borderColor: theme.palette.grey[400],
    padding: theme.spacing(1, 2),
    "&.disabled": {
      borderColor: theme.palette.action.disabled,
      color: theme.palette.action.disabled,
    },
    "&:not(.disabled):hover": {
      borderColor: theme.palette.primary.main,
    },
    "&.selected": {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  },
  formControl: {
    minWidth: 200,
    marginBottom: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
function FieldDetail() {
  const classes = useStyles();
  const ApiBaseUrl = config.ApiBaseUrl;
  const ApiBaseUrlImage = config.ApiBaseUrlImage;
  const { city, id } = useParams();
  const [field, setField] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("");
  const navigate = useNavigate();
  const [reservedTimeSlots, setReservedTimeSlots] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [availableTimes, setAvailableTimes] = useState([]);
  useEffect(() => {
    async function fetchFieldDetails() {
      try {
        const response = await fetch(`${ApiBaseUrl}api/field/${city}/${id}`);
        if (response.ok) {
          const data = await response.json();
          setField(data);
          localStorage.setItem("halısaha-name", data.name);

          setAvailableTimes(data.selectedTimes);
        } else {
          enqueueSnackbar("Failed to fetch field details.", {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar("Failed to fetch field details.", { variant: "error" });
      }
    }
    fetchFieldDetails();
  }, [id, city, ApiBaseUrl, enqueueSnackbar]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    async function fetchReservations() {
      try {
        const formattedDate = selectedDate.toISOString().split("T")[0];
        const response = await fetch(
          `${ApiBaseUrl}api/reservations/${city}/${id}/${formattedDate}`
        );
        if (response.ok) {
          const data = await response.json();
          setReservedTimeSlots(data.reservedTimes);
        } else {
          enqueueSnackbar("Failed to fetch reservations.", {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar("Failed to fetch reservations.", { variant: "error" });
      }
    }
    fetchReservations();
  }, [selectedDate, id, city, enqueueSnackbar]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const changeDay = (offset) => {
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(today.getDate() + 6);

    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + offset);

    if (newDate < today) {
      setSelectedDate(today);
    } else if (newDate > maxDate) {
      setSelectedDate(maxDate);
    } else {
      setSelectedDate(newDate);
    }
  };

  const goModel = () => {
    if (!selectedTime) {
      enqueueSnackbar("Lütfen bir saat seçiniz.", { variant: "warning" });
      return;
    }
    if (localStorage.getItem("userId") === null) {
      enqueueSnackbar("Lütfen giriş yapınız.", { variant: "warning" });
      return;
    }

    const tesisInformations = {
      city,
      id,
      selectedTime,
      selectedDate,
      servisDuraklari: field.servisDuraklari,
      kapora: field.kapora,
      servisFiyat: field.servisFiyat,
      paymentRequired: field.paymentRequired,
    };

    navigate("/payment", { state: { tesisInformations } });
  };
  if (!field) return <div>Loading...</div>;

  return (
    <div className="bg pt-5 pb-5">
      <div className="container ">
        <div className="rezervecard">
          <Card className="mb-4 shadow-sm">
            <Grid container>
              <Grid item xs={12} md={4}>
                <CardMedia
                  component="img"
                  image={`${ApiBaseUrlImage}${field.image}`}
                  alt="Halı Sahası"
                  style={{ objectFit: "cover", height: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CardContent>
                  <Typography variant="h3" component="h1">
                    {field.name}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <strong>Açıklama:</strong> {field.description}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" component="div">
                        <strong>Özellikler:</strong>
                        <div>
                          {field.features.map((feature, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: 4,
                              }}
                            >
                              {iconMapping[field.featuresIcons[index]] && (
                                <FontAwesomeIcon
                                  icon={iconMapping[field.featuresIcons[index]]}
                                  style={{ marginLeft: 8, marginRight: 8 }}
                                />
                              )}
                              {feature}
                            </div>
                          ))}
                        </div>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" component="p">
                        <strong>Fiyat:</strong> {field.price} ₺
                      </Typography>

                      <Typography variant="body2" component="p">
                        <strong>Kapora:</strong>
                        {field.kapora > 0 ? `${field.kapora} ₺` :'Kapora ücreti alınmamaktadır'}
                      </Typography>
                      <Typography variant="body2" component="p">
                        <strong>Maksimum Oyuncu Sayısı:</strong>{" "}
                        {field.maxplayer}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="body1" component="p">
                    <strong>Adres:</strong> {field.address}
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </div>

        <div className="card mt-4 shadow-sm p-3">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <ArrowCircleLeftIcon
                onClick={() => changeDay(-1)}
                sx={{
                  fontSize: 40,
                  cursor: "pointer",
                }}
              />
              <DatePicker
                selected={selectedDate}
                dateFormat="dd MMMM yyyy EEEEEEEEE"
                locale={trLocale}
                className="form-control"
                customInput={<TextField />}
              />
              <ArrowCircleRightIcon
                onClick={() => changeDay(1)}
                sx={{
                  fontSize: 40,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className="row">
              <Grid item xs={12}>
                <Typography variant="h6">Çalışma Saatleri</Typography>
                <Grid container spacing={2}>
                  {availableTimes.map((timeSlot) => (
                    <Grid item key={timeSlot}>
                      <Button
                        variant="outlined"
                        color={
                          selectedTime === timeSlot ? "primary" : "default"
                        }
                        onClick={() => handleTimeChange(timeSlot)}
                        className={`${classes.timeSlot} ${
                          reservedTimeSlots.includes(timeSlot) ? "disabled" : ""
                        } ${selectedTime === timeSlot ? "selected" : ""}`}
                        disabled={reservedTimeSlots.includes(timeSlot)}
                      >
                        {timeSlot}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </div>
            <Button
              variant="btn btn-danger "
              className="mt-3"
              onClick={() => goModel()}
            >
              Rezervasyon Sayfasına Git
            </Button>
          </div>
        </div>

        <Comments city={city} id={id} />
      </div>
    </div>
  );
}

export default FieldDetail;
