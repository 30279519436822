import React from 'react';
import { Card, CardContent, Typography, Container, Button } from '@mui/material';

function ReservationsInformations() {
    const busStop = localStorage.getItem("busStop");
    const userPhone = localStorage.getItem("user_phone");
    const selectedDate = new Date(localStorage.getItem("selectedDate"));
const halısahaName = localStorage.getItem("halısaha-name");
// Seçili tarihi bir gün ileri alıyoruz
    const nextDay = new Date(selectedDate);


// ISO formatına dönüştürüp sadece tarih kısmını alıyoruz
    const formattedDate = nextDay.toISOString().split('T')[0];
    const selectedTime = localStorage.getItem("selectedTime");

    const city = localStorage.getItem("city");
    const userName = localStorage.getItem("user_name");
    const userAddress = localStorage.getItem("user_address");

    const goHomePage = () => {
        window.location.href = "/rezervasyonlarim";
    };

    return (
        <div className="bg pt-5 pb-5">
            <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card sx={{ width: '100%' }}>
                    <CardContent>
                        <Typography variant="h5" component="div" gutterBottom>
                            Rezervasyon Bilgileri
                        </Typography>
                        <Typography variant="body1" gutterBottom><strong>Halı Saha:</strong> {halısahaName}</Typography>
                        <Typography variant="body1" gutterBottom><strong>Adı:</strong> {userName}</Typography>
                        <Typography variant="body1" gutterBottom><strong>Telefon:</strong> {userPhone}</Typography>
                        <Typography variant="body1" gutterBottom><strong>Adres:</strong> {userAddress}</Typography>
                        <Typography variant="body1" gutterBottom><strong>Şehir:</strong> {city}</Typography>
                        {busStop ? (
                            <Typography variant="body1" gutterBottom><strong>Durak:</strong> {busStop}</Typography>
                        ) : (
                            <Typography variant="body1" gutterBottom style={{ color: 'red' }}>Servis hizmeti Seçilmedi</Typography>
                        )}
                        <Typography variant="body1" gutterBottom><strong>Tarih:</strong> {formattedDate}</Typography>
                        <Typography variant="body1" gutterBottom><strong>Saat:</strong> {selectedTime}</Typography>
                    </CardContent>
                    <Button fullWidth variant="contained" onClick={goHomePage} sx={{ mt: 2, mb: 2, margin: 'auto' }}>
                        Rezervasyonlarıma Dön
                    </Button>
                </Card>
            </Container>
        </div>
    );
}

export default ReservationsInformations;
