import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";

function TeslimatIadeSartlari() {
  return (
    <div className="bg pt-5 pb-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={10} className="bg-white p-4 border rounded">
            <h2 className="text-center">Teslimat ve İade Şartları</h2>

            <h3>1. Taraflar</h3>
            <p>
              İşbu Teslimat ve İade Şartları ("Şartlar"), [Firma Adı] ("Şirket")
              ile Tesisbul.com üzerinden tesis kiralayan ve/veya rezervasyon
              yapan müşteriler ("Müşteri") arasında akdedilmiştir.
            </p>

            <h3>2. Tanımlar</h3>
            <ul>
              <li>
                <strong>Şirket:</strong> [Firma Adı], [Firma Adresi], [Firma
                Telefon Numarası], [Firma E-posta Adresi]
              </li>
              <li>
                <strong>Müşteri:</strong> Tesisbul.com üzerinden tesis kiralayan
                ve/veya rezervasyon yapan kişi
              </li>
              <li>
                <strong>Site:</strong> Tesisbul.com web sitesi ve mobil
                uygulaması
              </li>
            </ul>

            <h3>3. Teslimat</h3>
            <p>
              <strong>3.1. Teslimat Süreci:</strong> Tesisbul.com üzerinden
              yapılan rezervasyonlar sonucunda tesisin teslimatı, rezervasyonun
              onaylandığı tarih ve saatte gerçekleştirilir. Müşteri, rezervasyon
              sırasında belirttiği tarih ve saatte tesisi kullanma hakkına
              sahiptir.
            </p>
            <p>
              <strong>3.2. Teslimat Koşulları:</strong> Müşteri, tesisi
              kullanmadan önce rezervasyon detaylarında belirtilen şartlara
              uymakla yükümlüdür. Tesiste yapılacak herhangi bir değişiklik veya
              ek talep, önceden Şirket'e bildirilmelidir.
            </p>

            <h3>4. İade Şartları</h3>
            <p>
              <strong>4.1. Cayma Hakkı:</strong> Müşteri, teslim edilen tesis
              hizmetini herhangi bir sebep göstermeksizin teslim aldıktan sonra
              14 gün içinde iptal edebilir.
            </p>
            <p>
              <strong>4.2. İptal Prosedürü:</strong> İptal talebi, müşteri
              hizmetleri üzerinden veya web sitemizdeki iptal formu doldurularak
              yapılabilir. İptal talebi onaylandıktan sonra, müşteri tarafından
              belirtilen tarih ve saatte tesis teslim edilmelidir.
            </p>
            <p>
              <strong>4.3. İade Süreci:</strong> Tesisin teslim alınmasını
              takiben 5 iş günü içinde iptal işlemi tamamlanır. İptal tutarı,
              ödeme yapılan yönteme (kredi kartı, banka havalesi vb.) geri
              ödenir.
            </p>
            <p>
              <strong>4.4. İptal Ücretleri:</strong> Tesisin kusurlu olması
              durumunda iptal ücretleri firmamız tarafından karşılanır. Diğer
              durumlarda, iptal ücretleri müşteriye aittir.
            </p>
            <p>
              <strong>4.5. Cayma Hakkının İstisnaları:</strong> Müşterinin özel
              istekleri doğrultusunda özelleştirilen hizmetlerde cayma hakkı
              kullanılamaz.
            </p>

            <h3>5. Rezervasyon Değişiklikleri</h3>
            <p>
              <strong>5.1. Değişiklik Talepleri:</strong> Müşteri,
              rezervasyonunu değiştirmek istediğinde, bu talebi en az 24 saat
              öncesinden Şirket'e bildirmelidir. Değişiklik talepleri, mevcut
              müsaitlik durumuna bağlı olarak değerlendirilecektir.
            </p>
            <p>
              <strong>5.2. Ek Ücretler:</strong> Rezervasyon değişiklikleri ek
              ücretlere tabi olabilir. Müşteri, ek ücretleri kabul ettiğini
              beyan eder.
            </p>

            <h3>6. Sorumluluk</h3>
            <p>
              <strong>6.1. Hizmet Kalitesi:</strong> Şirket, tesisin rezervasyon
              sırasında belirtilen şartlara uygun olmasını sağlamak için gerekli
              özeni gösterir. Ancak, beklenmedik durumlar veya müşteri
              taleplerindeki değişiklikler nedeniyle oluşabilecek aksaklıklardan
              dolayı Şirket sorumlu tutulamaz.
            </p>
            <p>
              <strong>6.2. Müşteri Sorumluluğu:</strong> Müşteri, tesisin
              kullanım süresi boyunca tesise zarar vermemekle yükümlüdür. Tesise
              verilen zararlar, müşteri tarafından karşılanacaktır.
            </p>
            <p>
              <strong>6.3. Sigorta:</strong> Tesisin sigortalanması durumunda,
              sigorta şartları ve kapsamı müşteri ile paylaşılacaktır.
            </p>

            <h3>7. Gizlilik</h3>
            <p>
              <strong>7.1. Kişisel Bilgilerin Korunması:</strong> Müşterinin
              kişisel bilgileri, Gizlilik Politikası kapsamında işlenecek ve
              korunacaktır.
            </p>
            <p>
              <strong>7.2. Veri Paylaşımı:</strong> Müşterinin kişisel
              bilgileri, yasal gereklilikler haricinde üçüncü şahıslarla
              paylaşılmayacaktır.
            </p>

            <h3>8. Uyuşmazlıkların Çözümü</h3>
            <p>
              İşbu Sözleşme'den doğabilecek her türlü ihtilafın çözümünde
              İstanbul Mahkemeleri ve İcra Daireleri yetkilidir.
            </p>

            <h3>9. Yürürlük</h3>
            <p>
              Müşteri, Site üzerinden rezervasyon yaparak işbu Sözleşme'nin tüm
              koşullarını kabul etmiş sayılır. Sözleşme, rezervasyon işlemi
              tamamlandığında yürürlüğe girer.
            </p>

            <h3>10. İletişim Bilgileri</h3>
            <p>
              İşbu Sözleşme ile ilgili her türlü soru ve talepleriniz için
              bizimle iletişime geçebilirsiniz:
            </p>
            <li>E-posta: info@tesisbul.com</li>
            <li>Telefon: 0553 174 82 04 – 0505 061 96 88</li>
            <li>Adres: Körfez, Yeşil Pelit Cd., 55270 Atakum/Samsun</li>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TeslimatIadeSartlari;
