import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS dosyasını ekliyoruz
import { Container } from "react-bootstrap"; // Bootstrap Container bileşenini kullanacağız

function GizlilikSozlesme() {
  return (
      <div className="bg pt-5 pb-5">
        <Container>
          <div className="bg-white p-4 border rounded">
            {" "}
            {/* Beyaz arka plan ve kenarlık ile bir çerçeve */}
            <h2>Tesisbul.com Gizlilik Politikası</h2>
            <p>
              <strong>1. Giriş</strong>
              <br />
              Tesisbul.com olarak, kullanıcılarımızın gizliliğine büyük önem veriyoruz. Bu gizlilik politikası, Tesisbul.com ve mobil internet sitesi üzerinden yapılan rezervasyonlar ve sağlanan hizmetlerle ilgili olarak kişisel verilerin nasıl toplandığını, kullanıldığını, korunduğunu ve paylaşıldığını açıklamaktadır. Bu politikayı dikkatlice okumanızı tavsiye ederiz.
            </p>
            <p>
              <strong>2. Toplanan Bilgiler</strong>
              <br />
              Hizmetlerimizi kullanırken şu tür bilgileri toplayabiliriz:
              <ul>
                <li>Kişisel Bilgiler: Adı, soyadı, e-posta adresi, telefon numarası, fatura adresi vb.</li>
                <li>Rezervasyon Bilgileri: Rezervasyon yaptığınız tesis, rezervasyon tarih ve saati, kapora ödeme bilgileri vb.</li>
                <li>Teknik Bilgiler: IP adresi, tarayıcı türü, işletim sistemi, giriş ve çıkış sayfaları, tıklama verileri vb.</li>
                <li>Çerezler ve Benzeri Teknolojiler: Web sitemizi kullanımınızı takip etmek için çerezler ve benzeri teknolojiler kullanabiliriz.</li>
              </ul>
            </p>
            <p>
              <strong>3. Bilgi Toplama Yöntemleri</strong>
              <br />
              Kişisel bilgilerinizi şu yöntemlerle topluyoruz:
              <ul>
                <li>Web sitemizdeki ve mobil uygulamalardaki formlar</li>
                <li>Çerezler ve benzeri teknolojiler</li>
                <li>Üçüncü taraf analiz araçları</li>
              </ul>
            </p>
            <p>
              <strong>4. Bilgi Kullanımı</strong>
              <br />
              Topladığımız bilgileri şu amaçlarla kullanabiliriz:
              <ul>
                <li>Rezervasyon işlemlerini gerçekleştirmek ve onaylamak</li>
                <li>Müşteri hizmetleri sunmak</li>
                <li>Hizmetlerimizi iyileştirmek ve kişiselleştirmek</li>
                <li>Pazarlama ve tanıtım faaliyetleri yürütmek</li>
              </ul>
            </p>
            <p>
              <strong>5. Bilgi Paylaşımı</strong>
              <br />
              Kişisel bilgilerinizi şu durumlarda üçüncü taraflarla paylaşabiliriz:
              <ul>
                <li>Rezervasyon yaptığınız tesislerle</li>
                <li>Hizmet sağlayıcılarımızla</li>
                <li>Yasal gereklilikler doğrultusunda</li>
                <li>İş ortaklarımızla</li>
              </ul>
            </p>
            <p>
              <strong>6. Bilgi Güvenliği</strong>
              <br />
              Kişisel bilgilerinizi korumak için gerekli teknik ve idari önlemleri almaktayız. Ancak, internet üzerinden bilgi iletiminin tamamen güvenli olmadığını ve bu nedenle iletilen bilgilerin güvenliğini garanti edemeyeceğimizi belirtmek isteriz.
            </p>
            <p>
              <strong>7. Çocukların Gizliliği</strong>
              <br />
              18 yaşından küçük çocuklardan bilerek bilgi toplamıyoruz. Çocuğunuzun kişisel bilgilerini bizimle paylaştığını düşünüyorsanız, lütfen bizimle iletişime geçin.
            </p>
            <p>
              <strong>8. Kullanıcı Hakları</strong>
              <br />
              Kullanıcılar, kişisel bilgilerine erişme, bu bilgileri düzeltme, silme ve işlenmesine itiraz etme hakkına sahiptir. Bu hakları kullanmak için bizimle iletişime geçebilirsiniz.
            </p>
            <p>
              <strong>9. Gizlilik Politikasındaki Değişiklikler</strong>
              <br />
              Gizlilik politikamızda değişiklik yapmamız durumunda, bu sayfada güncellenmiş politikayı yayınlayacağız. Politikadaki önemli değişiklikler hakkında sizi bilgilendireceğiz.
            </p>
            <p>
              <strong>10. İletişim Bilgileri</strong>
              <br />
              Gizlilik politikamız hakkında sorularınız veya endişeleriniz varsa, lütfen aşağıdaki iletişim bilgileri üzerinden bizimle iletişime geçin:
              <ul>
                <li>E-posta: info@tesisbul.com</li>
                <li>Telefon: 0553 174 82 04 – 0505 061 96 88</li>
                <li>Adres: Körfez, Yeşil Pelit Cd., 55270 Atakum/Samsun</li>
              </ul>
            </p>
          </div>
        </Container>
      </div>
  );
}

export default GizlilikSozlesme;
