import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Tooltip, Grid, TextField, Button, Typography, Checkbox, FormControlLabel, makeStyles, CircularProgress, Modal, Backdrop, useTheme } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import config from '../service/api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  termsContainer: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      marginLeft: theme.spacing(1),
    },
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  loadingMessage: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    textAlign: 'center',
  },
}));

const SignInButton = ({ onPhoneVerified }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.phone.email/sign_in_button_v1.js';
    script.async = true;
    document.querySelector('.pe_signin_button').appendChild(script);

    window.phoneEmailListener = function (userObj) {
      const user_json_url = userObj.user_json_url;
      onPhoneVerified(user_json_url);
    };

    return () => {
      window.phoneEmailListener = null;
    };
  }, [onPhoneVerified]);

  return <div className="pe_signin_button" data-client-id="14452888547575098454"></div>;
};

const RegisterPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const ApiBaseUrl = config.ApiBaseUrl;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [adminkey, setAdminkey] = useState('');
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);
  const [verifyingPhone, setVerifyingPhone] = useState(false);
  const [lastRequestTime, setLastRequestTime] = useState(0); // Son istek zamanı

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handlePhoneVerified = async (user_json_url) => {
    if (loading || verifyingPhone) return;

    setVerifyingPhone(true);

    try {
      const response = await fetch(user_json_url);
      const data = await response.json();
      const { user_phone_number } = data;
      setPhoneVerified(true);

      // Telefon doğrulandıktan sonra kayıt işlemini başlat
      await handleRegister(user_phone_number);
    } catch (error) {
      enqueueSnackbar('Telefon numarası doğrulama sırasında bir hata oluştu.', { variant: 'error' });
    } finally {
      setVerifyingPhone(false);
    }
  };

  const handleRegister = async (phoneNumber) => {
    if (loading) return;

    const now = Date.now();
    if (now - lastRequestTime < 10000) { // 30 saniyede bir istek yapılmasına izin ver
      enqueueSnackbar('Lütfen 30 saniye bekleyin.', { variant: 'warning' });
      return;
    }

    setLastRequestTime(now); // Son isteğin zamanını güncelle
    setLoading(true); // İşlem başladı

    if (!validateEmail(email)) {
      setEmailError('Geçerli bir e-posta adresi girin.');
      setLoading(false); // İşlem bitti
      return;
    }

    if (!termsAccepted) {
      enqueueSnackbar('Kayıt olmak için kullanıcı sözleşmesini onaylamalısınız.', { variant: 'warning' });
      setLoading(false); // İşlem bitti
      return;
    }

    setEmailError('');

    try {
      const response = await fetch(`${ApiBaseUrl}api/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, lastname, email, password, adminkey, phoneNumber }),
      });

      if (response.ok) {
        enqueueSnackbar('Kayıt işlemi başarıyla tamamlandı.', { variant: 'success' });
        navigate('/login');
      } else {
        const data = await response.json();
        enqueueSnackbar(data.message || 'Kayıt işlemi sırasında bir hata oluştu.', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Kayıt işlemi sırasında bir hata oluştu.', { variant: 'error' });
      console.error('Registration error:', error);
    } finally {
      setLoading(false); // İşlem bitti
    }
  };

  return (
      <div className="bg">
        <Grid container justify="center" className="pt-5 pb-5">
          <Grid item xs={12} sm={8} md={6}>
            <div className="card">
              <div className="card-header">
                <Typography variant="h4">Kayıt Ol</Typography>
              </div>
              <div className="card-body">
                <form onSubmit={(e) => e.preventDefault()} className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                          label="İsim"
                          variant="outlined"
                          fullWidth
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                          label="Soyisim"
                          variant="outlined"
                          fullWidth
                          value={lastname}
                          onChange={(e) => setLastname(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                          label="Email"
                          variant="outlined"
                          fullWidth
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          error={!!emailError}
                          helperText={emailError}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                          label="Şifre"
                          variant="outlined"
                          fullWidth
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                          label="AdminKey"
                          variant="outlined"
                          fullWidth
                          value={adminkey}
                          onChange={(e) => setAdminkey(e.target.value)}
                          InputProps={{
                            endAdornment: (
                                <Tooltip
                                    title="Admin key sadece tesis oluşturmak isteyen kullanıcılar içindir."
                                    placement="right"
                                >
                                  <InfoOutlinedIcon style={{ cursor: 'pointer', color: 'blue' }} />
                                </Tooltip>
                            ),
                          }}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.termsContainer}>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                                color="primary"
                            />
                          }
                          label="Kullanıcı sözleşmesini onaylıyorum"
                      />
                      <a
                          style={{ marginBottom: 6 }}
                          href="https://tesisbul.com/kullanici-sozlesmesi"
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                        Kullanıcı Sözleşmesi
                      </a>
                    </Grid>
                    <Grid item xs={12} className={classes.centerContent}>
                      {!phoneVerified ? (
                          <>
                            <SignInButton onPhoneVerified={handlePhoneVerified} />
                            {verifyingPhone && (
                                <Modal
                                    open={verifyingPhone}
                                    className={classes.modal}
                                    onClose={() => setVerifyingPhone(false)}
                                >
                                  <div className={classes.paper}>
                                    <Typography className={classes.loadingMessage}>
                                      Telefon doğrulama işleminiz gerçekleşiyor, lütfen bekleyiniz.
                                    </Typography>
                                    <CircularProgress />
                                  </div>
                                </Modal>
                            )}
                          </>
                      ) : (
                          <Typography className={classes.loadingMessage}>
                            Telefon numaranız doğrulandı.
                          </Typography>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
        {verifyingPhone && (
            <Backdrop open={verifyingPhone} style={{ zIndex: theme.zIndex.modal + 1 }}>
              <div className={classes.paper}>
                <Typography className={classes.loadingMessage}>
                  Telefon doğrulama işleminiz gerçekleşiyor, lütfen bekleyiniz.
                </Typography>
                <CircularProgress />
              </div>
            </Backdrop>
        )}
      </div>
  );
};

export default RegisterPage;
