import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
} from "@material-ui/core";
import config from "../service/api";

const LoginPage = () => {
  const ApiBaseUrl = config.ApiBaseUrl;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${ApiBaseUrl}api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const { token, userId, adminkey, phoneNumber } = await response.json();
        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);
        localStorage.setItem("adminkey", adminkey);
        localStorage.setItem("phoneNumber", phoneNumber); // Telefon numarasını kaydediyoruz
        navigate("/");
        window.location.reload();
      } else {
        const data = await response.json();
        enqueueSnackbar(data.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Login error:", error);
      enqueueSnackbar("Giriş yapılırken bir hata oluştu", { variant: "error" });
    }
  };

  const handleRegister = () => {
    navigate("/register");
  };

  return (
    <div className="bg pt-5 pb-5">
      <Container maxWidth="sm">
        <Card>
          <CardHeader title="Giriş Yap" />
          <CardContent>
            <form onSubmit={handleLogin}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Şifre"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Giriş Yap
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleRegister}
                  >
                    Kayıt Ol
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

const LoginPageWithSnackbar = () => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <LoginPage />
    </SnackbarProvider>
  );
};

export default LoginPageWithSnackbar;
