import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Container,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  Box,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import {
  faCoffee,
  faParking,
  faShower,
  faCreditCard,
  faVideo,
  faBath,
  faToilet,
  faHandHolding,
  faUserShield,
  faShoePrints,
  faPrayingHands,
} from "@fortawesome/free-solid-svg-icons";
import config from "../service/api";
import { useNavigate } from "react-router-dom";

const StyledDropzoneContainer = styled("div")({
  border: "2px dashed #007bff",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  margin: "20px 0",
});

const featuresOptions = [
  { label: "Kafeterya", icon: faCoffee },
  { label: "Otopark", icon: faParking },
  { label: "Soyunma Odası", icon: faShower },
  { label: "Kredi Kartı", icon: faCreditCard },
  { label: "Kamera", icon: faVideo },
  { label: "Duş Hizmeti", icon: faBath },
  { label: "Wc", icon: faToilet },
  { label: "Eldiven Kiralama", icon: faHandHolding },
  { label: "Kaleci Kiralama", icon: faUserShield },
  { label: "Ayakkabı Kiralama", icon: faShoePrints },
  { label: "İbadet Alanı", icon: faPrayingHands },
];
const availableTimes = [
  "00:00-01:00",
  "00:30-01:30",
  "01:00-02:00",
  "01:30-02:30",
  "02:00-03:00",
  "02:30-03:30",
  "03:00-04:00",
  "03:30-04:30",
  "04:00-05:00",
  "04:30-05:30",
  "05:00-06:00",
  "05:30-06:30",
  "06:00-07:00",
  "06:30-07:30",
  "07:00-08:00",
  "07:30-08:30",
  "08:00-09:00",
  "08:30-09:30",
  "09:00-10:00",
  "09:30-10:30",
  "10:00-11:00",
  "10:30-11:30",
  "11:00-12:00",
  "11:30-12:30",
  "12:00-13:00",
  "12:30-13:30",
  "13:00-14:00",
  "13:30-14:30",
  "14:00-15:00",
  "14:30-15:30",
  "15:00-16:00",
  "15:30-16:30",
  "16:00-17:00",
  "16:30-17:30",
  "17:00-18:00",
  "17:30-18:30",
  "18:00-19:00",
  "18:30-19:30",
  "19:00-20:00",
  "19:30-20:30",
  "20:00-21:00",
  "20:30-21:30",
  "21:00-22:00",
  "21:30-22:30",
  "22:00-23:00",
  "22:30-23:30",
  "23:00-00:00",
  "23:30-00:30",
];

function Panel() {
  const ApiBaseUrl = config.ApiBaseUrl;
  const [formData, setFormData] = useState({
    city: "",
    name: "",
    description: "",
    address: "",
    features: [],
    featuresIcons: [],
    price: "",
    kapora: "",
    maxplayer: "",
    directions: "",
    image: "",
    servisHizmeti: false,
    servisDuraklari: [""],
    servisFiyat: "",
    selectedTimes: [],
    paymentRequired: false,
  });
  const [message, setMessage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    if (name === "servisHizmeti" && !checked) {
      setFormData((prevState) => ({
        ...prevState,
        servisDuraklari: [""],
        servisFiyat: "",
      }));
    }

    if (name === "paymentRequired" && !checked) {
      setFormData((prevState) => ({
        ...prevState,
        kapora: "", // Kapora alanını sıfırla
      }));
    }
  };

  const handleFeatureChange = (event) => {
    const value = event.target.value;
    const selectedFeaturesIcons = value.map((label) => {
      const selectedFeature = featuresOptions.find(
        (option) => option.label === label
      );
      return selectedFeature ? selectedFeature.icon.iconName : null;
    });

    setFormData((prevState) => ({
      ...prevState,
      features: typeof value === "string" ? value.split(",") : value,
      featuresIcons: selectedFeaturesIcons,
    }));
  };

  const onDrop = (acceptedFiles) => {
    setImageFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!imageFile) {
      console.error("No image file selected.");
      return;
    }

    try {
      const data = new FormData();
      data.append("image", imageFile);

      const imageResponse = await fetch(`${ApiBaseUrl}api/upload/image`, {
        method: "POST",
        body: data,
      });

      if (imageResponse.ok) {
        const imageResult = await imageResponse.json();
        setImageUrl(imageResult.imageUrl);

        const userId = localStorage.getItem("userId");
        if (!userId) {
          console.error("User ID not found in localStorage.");
          return;
        }

        const formDataWithUserId = {
          ...formData,
          userId: userId,
          image: imageResult.imageUrl,
        };

        const facilityResponse = await fetch(
          `${ApiBaseUrl}api/${formData.city}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formDataWithUserId),
          }
        );

        if (facilityResponse.ok) {
          setMessage(`${formData.name} tesis başarıyla eklendi.`);
        } else {
          console.error("Halısaha tesis eklenirken bir hata oluştu.");
        }
      } else {
        const errorData = await imageResponse.json();
        console.error("Görsel yüklenirken bir hata oluştu:", errorData);
      }
    } catch (error) {
      console.error("Görsel yüklenirken bir hata oluştu:", error);
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleCreateFacility = async (e) => {
    e.preventDefault();
    await handleSubmit(e);
    enqueueSnackbar("Tesis oluşturuldu", { variant: "success" });
    navigate("../");
  };

  const handleServisDuragiChange = (index, event) => {
    const newServisDuraklari = formData.servisDuraklari.map((durak, i) =>
      i === index ? event.target.value : durak
    );
    setFormData((prevState) => ({
      ...prevState,
      servisDuraklari: newServisDuraklari,
    }));
  };

  const addServisDuragi = () => {
    setFormData((prevState) => ({
      ...prevState,
      servisDuraklari: [...prevState.servisDuraklari, ""],
    }));
  };

  const removeServisDuragi = (index) => {
    setFormData((prevState) => {
      const newServisDuraklari = [...prevState.servisDuraklari];
      newServisDuraklari.splice(index, 1);
      return {
        ...prevState,
        servisDuraklari: newServisDuraklari,
      };
    });
  };

  const handleTimeSelection = (time) => {
    const selectedTimes = formData.selectedTimes.includes(time)
      ? formData.selectedTimes.filter((t) => t !== time)
      : [...formData.selectedTimes, time];

    setFormData((prevState) => ({
      ...prevState,
      selectedTimes,
    }));
  };

  return (
    <Box
      className="bg pt-5 pb-5"
      sx={{
        backgroundColor: "#f0f0f0",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          backgroundColor: "white",
          borderRadius: "15px",
          padding: "20px",
          boxShadow: "0px 8px 24px rgba(149, 157, 165, 0.2)",
        }}
      >
        <Box mt={4} mb={4}>
          <Typography variant="h4" align="center" gutterBottom>
            Halısaha Tesisi Ekle
          </Typography>
          <form onSubmit={handleCreateFacility}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  select
                  label="İl Seçiniz"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  sx={{ my: 2 }}
                >
                  <MenuItem value="Erzurum">Erzurum</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Tesis İsmi"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Açıklama"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Adres"
                  variant="outlined"
                  fullWidth
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <Select
                    multiple
                    value={formData.features}
                    onChange={handleFeatureChange}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {featuresOptions.map((option) => (
                      <MenuItem key={option.label} value={option.label}>
                        <Checkbox
                          checked={formData.features.includes(option.label)}
                        />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Fiyat"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Maksimum Oyuncu Sayısı"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="maxplayer"
                  value={formData.maxplayer}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Google Maps Linki"
                  variant="outlined"
                  fullWidth
                  name="directions"
                  value={formData.directions}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item>
                <FormControl fullWidth margin="normal">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="paymentRequired"
                        checked={formData.paymentRequired}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Ödeme Alınacak mı?"
                  />
                </FormControl>
                {formData.paymentRequired && (
                  <TextField
                    fullWidth
                    margin="normal"
                    name="kapora"
                    label="Kapora Miktarı"
                    value={formData.kapora}
                    onChange={handleChange}
                    type="number"
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.servisHizmeti}
                      onChange={handleCheckboxChange}
                      name="servisHizmeti"
                    />
                  }
                  label="Servis Hizmeti Var Mı?"
                />
              </Grid>
              {formData.servisHizmeti && (
                <>
                  <Grid item xs={12}>
                    {formData.servisDuraklari.map((durak, index) => (
                      <Box key={index} display="flex" alignItems="center">
                        <TextField
                          label={`Servis Durağı ${index + 1}`}
                          variant="outlined"
                          fullWidth
                          value={durak}
                          onChange={(e) => handleServisDuragiChange(index, e)}
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => removeServisDuragi(index)}
                        >
                          Sil
                        </Button>
                      </Box>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addServisDuragi}
                    >
                      Yeni Servis Durağı Ekle
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Servis Fiyatı"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="servisFiyat"
                      value={formData.servisFiyat}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <StyledDropzoneContainer {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Typography>
                    Fotoğraf eklemek için tıklayın veya sürükleyip bırakın
                  </Typography>
                </StyledDropzoneContainer>
                {imageFile && (
                  <Typography>{imageFile.name} adlı dosya seçildi.</Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography>Uygun Saatler:</Typography>
                <Box display="flex" flexWrap="wrap">
                  {availableTimes.map((time) => (
                    <FormControlLabel
                      key={time}
                      control={
                        <Checkbox
                          checked={formData.selectedTimes.includes(time)}
                          onChange={() => handleTimeSelection(time)}
                        />
                      }
                      label={time}
                    />
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Tesisi Oluştur
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>
  );
}

export default Panel;
