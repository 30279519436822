import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import logo from "../assets/logoNavbar.png";
function Hakkimizda() {
  return (
      <div className="bg pt-5 pb-5">
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={10} className="bg-white p-4 border rounded">
          <h2 className="text-center mb-4">Hakkımızda</h2>

          <Row className="mb-4">
            <Col md={4}>
              <Image
                src={logo}
                alt="Tesibul.com Logo"
                fluid
                className="rounded mb-3"
              />
            </Col>
            <Col md={8}>
              <p>
                Tesisbul.com, spor tesislerini kolayca rezerve edebilmeniz için
                geliştirilmiş bir platformdur. Misyonumuz, kullanıcılarımıza en
                iyi tesisleri en uygun fiyatlarla sunmak ve rezervasyon
                süreçlerini kolaylaştırmaktır.
              </p>
              <p>
                Kuruluşumuzdan bu yana, müşterilerimizin memnuniyetini ön planda
                tutarak hizmetlerimizi sürekli olarak geliştirdik. Spor
                tesisleri işletmecileri ile iş birliği yaparak, geniş bir tesis
                yelpazesi sunmaktayız.
              </p>
            </Col>
          </Row>

          <h3>Vizyonumuz</h3>
          <p>
            Spor tesisleri rezervasyonunda bir numaralı tercih olmak ve
            kullanıcılarımıza en iyi deneyimi sunmak.
          </p>

          <h3>Misyonumuz</h3>
          <p>
            Kullanıcılarımıza geniş bir tesis yelpazesi sunarak, kolay ve hızlı
            rezervasyon imkanı sağlamak.
          </p>

          <h3>Değerlerimiz</h3>
          <ul>
            <li>
              <strong>Müşteri Memnuniyeti:</strong> Müşterilerimizin memnuniyeti
              her zaman önceliğimizdir.
            </li>
            <li>
              <strong>Güvenilirlik:</strong> Hizmetlerimizi güvenilir ve şeffaf
              bir şekilde sunarız.
            </li>
            <li>
              <strong>Kalite:</strong> En yüksek kalitede hizmet sunmak için
              sürekli olarak çalışırız.
            </li>
            <li>
              <strong>İnovasyon:</strong> Teknolojiyi en iyi şekilde kullanarak,
              hizmetlerimizi sürekli olarak geliştiririz.
            </li>
          </ul>

          <h3>Ekibimiz</h3>
          <p>
            Tesisbul.com ekibi, spor tesisleri rezervasyonunda uzmanlaşmış
            profesyonellerden oluşmaktadır. Ekibimiz, kullanıcılarımıza en iyi
            hizmeti sunmak için sürekli olarak çalışmaktadır.
          </p>

          <h3>İletişim</h3>
          <p>
            Sorularınız veya geri bildirimleriniz için bizimle iletişime
            geçmekten çekinmeyin:
          </p>
          <p>
            <strong>Adres:</strong> Körfez, Yeşil Pelit Cd., 55270 Atakum/Samsun
          </p>
          <p>
            <strong>E-posta:</strong> info@tesisbul.com
          </p>
          <p>
            <strong>Telefon:</strong> 0553 174 82 04 – 0505 061 96 88
          </p>
        </Col>
      </Row>
    </Container>
      </div>
  );
}

export default Hakkimizda;
