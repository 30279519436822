import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import config from "../service/api";

function CreatePlayer() {
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [experience, setExperience] = useState("");
  const [position, setPosition] = useState("");
  const [city, setCity] = useState("");
  const [age, setAge] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const ApiBaseUrl = config.ApiBaseUrl;

  const onDrop = (acceptedFiles) => {
    setImage(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let imageUrl = "";
      if (image) {
        const formData = new FormData();
        formData.append("image", image);
        const imageResponse = await axios.post(
          `${ApiBaseUrl}api/upload/image/player`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        imageUrl = imageResponse.data.imageUrlplayer;
      }

      await axios.post(`${ApiBaseUrl}api/create/player`, {
        name,
        surname,
        phoneNumber,
        experience,
        position,
        city,
        age,
        price,
        imageUrl,
      });

      enqueueSnackbar("Oyuncu başarıyla oluşturuldu.", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Oyuncu oluşturulamadı.", { variant: "error" });
    }
  };

  return (
    <div className="bg pt-5 pb-5">
      <Container
        maxWidth="sm"
        className="bgwhite text-center"
        sx={{
          borderRadius: "20px", // Kenarları oval yapma
          overflow: "hidden", // İçeriği taşan kısımları gizleme
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Kiralık Oyuncu Formu
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="İsim"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Soyisim"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Telefon Numarası"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Deneyim Metni"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Pozisyon"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Şehir"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Yaş"
                type="number"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fiyat"
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed gray",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <input {...getInputProps()} />
                {image ? (
                  <Typography>{image.name}</Typography>
                ) : (
                  <Typography>
                    Bir resim sürükleyin ya da seçmek için tıklayın
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Oyuncuyu Kaydet
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default CreatePlayer;
