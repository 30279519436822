import React from "react";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import BookIcon from "@mui/icons-material/Book";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoginIcon from "@mui/icons-material/Login";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import logo from "../assets/logoNavbar.png";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const isLoggedIn = !!localStorage.getItem("token");
  const isAdmin = localStorage.getItem("adminkey") === "12369874";
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/");
    window.location.reload();
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
  };

  return (
    <footer className="footer-section mt-5">
      <div className="container">
        <div className="footer-cta pt-3 pb-3">
          <div className="row">
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="fas fa-map-marker-alt"></i>
                <div className="cta-text">
                  <h4>ADRES</h4>
                  <span>Körfez, Yeşil Pelit Cd., 55270 Atakum/Samsun</span>
                </div>

              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="fas fa-phone"></i>
                <div className="cta-text">
                  <h4>Telefon</h4>
                  <span>0553 174 82 04 – 0505 061 96 88</span>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-2 mb-10">
              <div className="single-cta">
                <i className="far fa-envelope-open"></i>
                <div className="cta-text">
                  <h4>MAİL</h4>
                  <span>info@tesisbul.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="/">
                    <img src={logo} className="img-fluid" alt="logo" />
                  </a>
                </div>
                <div className="footer-text">
                  <p>
                    TesisBul.com'a Hoş geldiniz! Burası, futbol tutkunlarını bir
                    araya getiren ve mükemmel sahalarda buluşturan bir platform.
                    Halı saha tesislerini kolayca kiralayabilir, rezervasyon ve
                    ödeme işlemlerinizi hızlı ve güvenli bir şekilde
                    gerçekleştirebilirsiniz. Aynı zamanda kiralık oyuncu hizmeti
                    sayesinde takımınızı tamamlayabilir ve maç keyfini doyasıya
                    yaşayabilirsiniz. Haydi, TesisBul.com ile futbolun keyfini
                    çıkarın ve en iyi sahalarda buluşun!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Sayfalar</h3>
                </div>
                <ul>
                  <li>
                    <HomeIcon className="footer-icon" />
                    <Link to="/">Anasayfa</Link>
                  </li>
                  <li>
                    <AddCircleOutlineIcon className="footer-icon" />
                    {isAdmin ? (
                      <Link to="/panel">Hemen Tesis Oluştur</Link>
                    ) : (
                      <span
                        onClick={() =>
                          alert(
                            "Yetkili Kullanıcı değilsiniz! Tesis Oluşturmak İçin İletişime Geçiniz"
                          )
                        }
                      >
                        Hemen Tesis Oluştur
                      </span>
                    )}
                  </li>
                  {isLoggedIn && (
                    <>
                      <li>
                        <BookIcon className="footer-icon" />
                        <Link to="/rezervasyonlarim">Rezervasyonlarım</Link>
                      </li>
                      <li>
                        <SportsSoccerIcon className="footer-icon" />
                        {isAdmin ? (
                          <Link to="/tesislerim">Tesislerim</Link>
                        ) : (
                          <span
                            onClick={() =>
                              alert(
                                "Yetkili Kullanıcı değilsiniz! Tesis Oluşturmak İçin İletişime Geçiniz"
                              )
                            }
                          >
                            Tesislerim
                          </span>
                        )}
                      </li>
                    </>
                  )}
                  <li>
                    <PersonAddIcon className="footer-icon" />
                    <Link to="/createplayer">Kiralık Oyuncu Ol</Link>
                  </li>
                  {isLoggedIn ? (
                    <li>
                      <ExitToAppIcon className="footer-icon" />
                      <span onClick={handleLogout}>Çıkış Yap</span>
                    </li>
                  ) : (
                    <>
                      <li>
                        <LoginIcon className="footer-icon" />
                        <Link to="/login">Giriş Yap</Link>
                      </li>
                      <li>
                        <PersonAddIcon className="footer-icon" />
                        <Link to="/register">Kayıt Ol</Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Sosyal Medya</h3>
                </div>
                <div className="footer-social-icon">
                  <a href="https://www.instagram.com/tesisbulcom?igsh=andveTA5MzNzaWdh">
                    <i className="fa-brands fa-instagram instagram-bg"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter twitter-bg"></i>
                  </a>
                  <a href="https://web.whatsapp.com/send?phone=905531748204">
                    <i
                      className="fab fa-whatsapp whatsapp-bg"
                      style={{ backgroundColor: "green" }}
                    ></i>
                  </a>
                </div>
                <div className="footer-widget">
                  <div className="footer-widget-heading mt-5">
                    <h3>Sözleşmeler</h3>
                  </div>
                  <ul>
                    <li>
                      <i className="fa fa-file-alt fa-fw mr-1"></i>
                      <Link to="/gizlilik-sozlesmesi">Gizlilik Politikası</Link>
                    </li>
                    <li>
                      <i className="fa fa-file-alt fa-fw mr-1"></i>
                      <Link to="/kullanici-sozlesmesi">
                        Kullanıcı Sözleşmesi
                      </Link>
                    </li>

                    <li>
                      <i className="fa fa-file-alt fa-fw mr-1"></i>
                      <Link to="/teslimat-iade-sartlari">
                        Teslimat İade Şartları
                      </Link>
                    </li>
                    <li>
                      <i className="fa fa-info-circle fa-fw mr-1"></i>
                      <Link to="/hakkimizda">Hakkımızda</Link>
                    </li>
                    <li>
                      <i className="fa fa-info-circle fa-fw mr-1"></i>
                      <Link to="/iletisim">İletişim</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 text-center">
              <div className="copyright-text text-center">
                <p>
                  Copyright &copy; 2024, Bütün hakları saklıdır{" "}
                  <a href="https://www.linkedin.com/in/yusuf-kerim-sar%C4%B1ta%C5%9F-94b172219/">
                    Yusuf Kerim Sarıtaş
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
