import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Container,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  Box,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faParking,
  faShower,
  faCreditCard,
  faVideo,
  faBath,
  faToilet,
  faHandHolding,
  faUserShield,
  faShoePrints,
  faPrayingHands,
} from "@fortawesome/free-solid-svg-icons";
import config from "../service/api";
import { useParams, useHistory, useNavigate } from "react-router-dom";

const StyledDropzoneContainer = styled("div")({
  border: "2px dashed #007bff",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  margin: "20px 0",
});

const featuresOptions = [
  { label: "Kafeterya", icon: faCoffee },
  { label: "Otopark", icon: faParking },
  { label: "Soyunma Odası", icon: faShower },
  { label: "Kredi Kartı", icon: faCreditCard },
  { label: "Kamera", icon: faVideo },
  { label: "Duş Hizmeti", icon: faBath },
  { label: "Wc", icon: faToilet },
  { label: "Eldiven Kiralama", icon: faHandHolding },
  { label: "Kaleci Kiralama", icon: faUserShield },
  { label: "Ayakkabı Kiralama", icon: faShoePrints },
  { label: "İbadet Alanı", icon: faPrayingHands },
];
const availableTimes = [
  "00:00-01:00",
  "00:30-01:30",
  "01:00-02:00",
  "01:30-02:30",
  "02:00-03:00",
  "02:30-03:30",
  "03:00-04:00",
  "03:30-04:30",
  "04:00-05:00",
  "04:30-05:30",
  "05:00-06:00",
  "05:30-06:30",
  "06:00-07:00",
  "06:30-07:30",
  "07:00-08:00",
  "07:30-08:30",
  "08:00-09:00",
  "08:30-09:30",
  "09:00-10:00",
  "09:30-10:30",
  "10:00-11:00",
  "10:30-11:30",
  "11:00-12:00",
  "11:30-12:30",
  "12:00-13:00",
  "12:30-13:30",
  "13:00-14:00",
  "13:30-14:30",
  "14:00-15:00",
  "14:30-15:30",
  "15:00-16:00",
  "15:30-16:30",
  "16:00-17:00",
  "16:30-17:30",
  "17:00-18:00",
  "17:30-18:30",
  "18:00-19:00",
  "18:30-19:30",
  "19:00-20:00",
  "19:30-20:30",
  "20:00-21:00",
  "20:30-21:30",
  "21:00-22:00",
  "21:30-22:30",
  "22:00-23:00",
  "22:30-23:30",
  "23:00-00:00",
  "23:30-00:30",
];
function TesisUpdate() {
  const ApiBaseUrl = config.ApiBaseUrl;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    city: "",
    name: "",
    description: "",
    address: "",
    features: [],
    featuresIcons: [],
    price: "",
    kapora: "",
    maxplayer: "",
    directions: "",
    servisHizmeti: false,
    servisDuraklari: [""],
    servisFiyat: "",
    selectedTimes: [],
    paymentRequired: false,
  });
  const [message, setMessage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const { city, id } = useParams();

  useEffect(() => {
    if (id) {
      async function fetchHalisaha() {
        try {
          const response = await fetch(`${ApiBaseUrl}api/field/${city}/${id}`);
          if (response.ok) {
            const data = await response.json();
            setFormData({
              city: data.city,
              name: data.name,
              description: data.description,
              address: data.address,
              features: data.features,
              featuresIcons: data.featuresIcons,
              price: data.price,
              kapora: data.kapora,
              maxplayer: data.maxplayer,
              directions: data.directions,
              servisHizmeti: data.servisHizmeti,
              servisDuraklari: data.servisDuraklari,
              servisFiyat: data.servisFiyat,
              selectedTimes: data.selectedTimes,
              paymentRequired: data.paymentRequired,
            });
            setImageUrl(data.image);
          } else {
            enqueueSnackbar("Failed to fetch field details.", {
              variant: "error",
            });
          }
        } catch (error) {
          enqueueSnackbar("Failed to fetch field details.", {
            variant: "error",
          });
        }
      }
      fetchHalisaha();
    }
  }, [city, id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        console.error("User ID not found in localStorage.");
        return;
      }

      const formDataWithUserId = {
        ...formData,
        userId: userId,
      };

      const facilityResponse = await fetch(
        `${ApiBaseUrl}api/${formData.city}/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formDataWithUserId),
        }
      );

      if (facilityResponse.ok) {
        setMessage(`${formData.name} tesisi başarıyla güncellendi.`);
      } else {
        console.error("Halısaha tesisi güncellenirken bir hata oluştu.");
        const errorData = await facilityResponse.json();
        enqueueSnackbar(
          errorData.message || "An error occurred while updating the facility.",
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      console.error("Halısaha tesisi güncellenirken bir hata oluştu:", error);
      enqueueSnackbar("An error occurred while updating the facility.", {
        variant: "error",
      });
    }
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    // Kapora alanını sıfırlama kontrolü
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleFeatureChange = (event) => {
    const value = event.target.value;
    const selectedFeaturesIcons = value.map((label) => {
      const selectedFeature = featuresOptions.find(
        (option) => option.label === label
      );
      return selectedFeature ? selectedFeature.icon.iconName : null;
    });

    setFormData((prevState) => ({
      ...prevState,
      features: typeof value === "string" ? value.split(",") : value,
      featuresIcons: selectedFeaturesIcons,
    }));
  };

  const onDrop = (acceptedFiles) => {
    setImageFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  const exit = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    window.location.reload();
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleCreateFacility = async (e) => {
    e.preventDefault();
    await handleSubmit(e);
    enqueueSnackbar("Tesis Güncellendi", { variant: "success" });
    navigate("/tesislerim");
  };

  const handleServisDuragiChange = (index, event) => {
    const newServisDuraklari = formData.servisDuraklari.map((durak, i) =>
      i === index ? event.target.value : durak
    );
    setFormData((prevState) => ({
      ...prevState,
      servisDuraklari: newServisDuraklari,
    }));
  };

  const handleAddServisDuragi = () => {
    setFormData((prevState) => ({
      ...prevState,
      servisDuraklari: [...prevState.servisDuraklari, ""],
    }));
  };

  const handleRemoveServisDuragi = (index) => {
    const newServisDuraklari = formData.servisDuraklari.filter(
      (_, i) => i !== index
    );
    setFormData((prevState) => ({
      ...prevState,
      servisDuraklari: newServisDuraklari,
    }));
  };
  const handleTimeSelection = (event) => {
    const { value, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      selectedTimes: checked
        ? [...prevState.selectedTimes, value]
        : prevState.selectedTimes.filter((time) => time !== value),
    }));
  };
  return (
    <Box
      className="bg pt-5 pb-5"
      sx={{
        backgroundColor: "#f0f0f0",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          backgroundColor: "white",
          borderRadius: "15px",
          padding: "20px",
          boxShadow: "0px 8px 24px rgba(149, 157, 165, 0.2)",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          align="center"
          gutterBottom
          sx={{ mb: 3 }}
        >
          Halısaha Tesisi Güncelleme
        </Typography>

        <form onSubmit={handleCreateFacility}>
          <TextField
            select
            label="İl Seçiniz"
            name="city"
            value={formData.city}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            sx={{ my: 2 }}
          >
            <MenuItem value="Erzurum">Erzurum</MenuItem>
          </TextField>
          <TextField
            label="Tesis Adı"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            sx={{ my: 2 }}
          />
          <TextField
            label="Tesis Açıklaması"
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            sx={{ my: 2 }}
          />
          <TextField
            label="Adres"
            name="address"
            value={formData.address}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            sx={{ my: 2 }}
          />
          <FormControl fullWidth variant="outlined" sx={{ my: 2 }}>
            <Typography variant="body1">Özellikler</Typography>
            <Select
              multiple
              value={formData.features}
              onChange={handleFeatureChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    overflow: "auto",
                  },
                },
              }}
            >
              {featuresOptions.map((feature) => (
                <MenuItem key={feature.label} value={feature.label}>
                  <Checkbox
                    checked={formData.features.includes(feature.label)}
                  />
                  <FontAwesomeIcon
                    icon={feature.icon}
                    style={{ marginRight: 8 }}
                  />
                  <ListItemText primary={feature.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Fiyatı Giriniz"
            name="price"
            type="number"
            value={formData.price}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            sx={{ my: 2 }}
          />
          <Grid item>
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                control={
                  <Checkbox
                    name="paymentRequired"
                    checked={formData.paymentRequired}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Ödeme Alınacak mı?"
              />
            </FormControl>
            {formData.paymentRequired && (
              <TextField
                fullWidth
                margin="normal"
                name="kapora"
                label="Kapora Miktarı"
                value={formData.kapora}
                onChange={handleChange}
                type="number"
              />
            )}
          </Grid>
          <TextField
            label="Maksimum Oyuncu Sayısı"
            name="maxplayer"
            type="number"
            value={formData.maxplayer}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            sx={{ my: 2 }}
          />
          <TextField
            label="Google Maps linki"
            name="directions"
            value={formData.directions}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            sx={{ my: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="servisHizmeti"
                checked={formData.servisHizmeti}
                onChange={handleChange}
              />
            }
            label="Servis Hizmetiniz Var mı?"
          />

          {formData.servisHizmeti && (
            <div>
              {formData.servisDuraklari.map((durak, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", my: 1 }}
                >
                  <TextField
                    label={`Servis Durağı ${index + 1}`}
                    value={durak}
                    onChange={(e) => handleServisDuragiChange(index, e)}
                    fullWidth
                    variant="outlined"
                    sx={{ mr: 1 }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRemoveServisDuragi(index)}
                    sx={{ ml: 1 }}
                  >
                    Sil
                  </Button>
                </Box>
              ))}
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddServisDuragi}
                sx={{ mt: 2 }}
              >
                Yeni Servis Durağı Ekle
              </Button>
              <TextField
                label="Servis Fiyatı"
                name="servisFiyat"
                type="number"
                value={formData.servisFiyat}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ my: 2 }}
              />
            </div>
          )}

          <Typography variant="h6" gutterBottom>
            Çalışma Saatlerini Seçiniz
          </Typography>
          <Grid container spacing={2}>
            {availableTimes.map((time) => (
              <Grid item xs={6} sm={4} md={3} key={time}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={time}
                      checked={formData.selectedTimes.includes(time)}
                      onChange={handleTimeSelection}
                    />
                  }
                  label={time}
                />
              </Grid>
            ))}
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Tesisi Güncelle
          </Button>
        </form>
      </Container>
    </Box>
  );
}

export default TesisUpdate;
