import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PlayersList from "./GetPlayers";
import "./home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareParking,
  faMugSaucer,
  faShower,
  faCreditCard,
  faVideo,
  faCoffee,
  faParking,
  faBath,
  faToilet,
  faHandHolding,
  faUserShield,
  faShoePrints,
  faPrayingHands,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import config from "../service/api";
import { Divider, Typography } from "@mui/material";

const iconMapping = {
  "square-parking": faSquareParking,
  "mug-saucer": faMugSaucer,
  shower: faShower,
  "credit-card": faCreditCard,
  video: faVideo,
  coffee: faCoffee,
  parking: faParking,
  bath: faBath,
  toilet: faToilet,
  "hand-holding": faHandHolding,
  "user-shield": faUserShield,
  "shoe-prints": faShoePrints,
  "hands-praying": faPrayingHands,
};

function Home() {
  const ApiBaseUrl = config.ApiBaseUrl;
  const ApiBaseUrlImage = config.ApiBaseUrlImage;

  const [selectedCity, setSelectedCity] = useState("Erzurum");
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState("Giriş Yap");
localStorage.removeItem("user_name");
localStorage.removeItem("user_phone");
localStorage.removeItem("user_address");
localStorage.removeItem("selectedDate");
localStorage.removeItem("selectedTime");
localStorage.removeItem("city");
localStorage.removeItem("busStop");
localStorage.removeItem("halısaha-name");

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await fetch(`${ApiBaseUrl}api/${selectedCity}`);
        if (response.ok) {
          const data = await response.json();
          setFields(data);
          if (localStorage.getItem("userId")) {
            setLoginInfo("ÇIKIŞ YAP");
          } else {
            setLoginInfo("GİRİŞ YAP");
          }
        } else {
          console.error(
              "Seçilen şehir için saha bilgileri alınırken hata oluştu."
          );
        }
      } catch (error) {
        console.error(
            "Seçilen şehir için saha bilgileri alınırken hata oluştu:",
            error
        );
      } finally {
        setLoading(false);
      }
    }

    if (selectedCity) {
      fetchData();
    }
  }, [selectedCity]);

  const handleChange = (e) => {
    const city = e.target.value;
    setSelectedCity(city);
  };

  const handleReservationClick = (city, id) => {
    navigate(`/field/${city}/${id}`);
  };

  const loginpagefunction = () => {
    if (localStorage.getItem("userId")) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      setLoginInfo("GİRİŞ YAP");
      window.location.reload();
    } else {
      navigate("/login");
    }
  };

  const rota = () => {
    navigate("/tesislerim");
  };

  const truncateText = (text, length) => {
    if (text.length <= length) {
      return text;
    }
    return text.substring(0, length) + "...";
  };

  const ReadMore = ({ children }) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    return (
        <p className="card-text">
          {isReadMore ? truncateText(children, 65) : children}
          <span onClick={toggleReadMore} className="read-or-hide text-primary" style={{ cursor: "pointer" }}>
          {isReadMore ? "Daha Fazla " : " Daha Az Göster"}
        </span>
        </p>
    );
  };

  return (
      <>
        <div className="bg">
          <div className="container">
            <div className="mb-5 pt-5" style={{ maxWidth: "100%", overflowX: "auto" }}>
              <select
                  className="form-select"
                  aria-label="Şehir Seçiniz"
                  value={selectedCity}
                  onChange={handleChange}
              >
                <option value="Erzurum">Erzurum</option>
              </select>
            </div>
            <Typography
                textAlign={"center"}
                variant="h3"
                gutterBottom
                style={{
                  fontFamily: "Poppins, Arial",
                  color: "white",
                }}
            >
              Hemen Tesis Bul
            </Typography>
            <Divider
                style={{
                  backgroundColor: "white",
                  marginBottom: 50,
                  height: "6px",
                  width: "50%",
                  margin: "0 auto",
                }}
            />
            {loading ? (
                <div className="d-flex justify-content-center pt-5 pb-5 text-white">
                  <div className="spinner-border" role="status">
                <span className="visually-hidden text-white pt-5 pb-5">
                  Tesisler Getiriliyor...
                </span>
                  </div>
                </div>
            ) : (
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 pt-5">
                  {fields?.map((field, index) => (
                      <div key={index} className="col mb-4">
                        <div className="card h-100">
                          <img
                              src={`${ApiBaseUrlImage}${field.image}`}
                              className="card-img-top"
                              alt="Halı Sahası"
                          />
                          <div className="card-body d-flex flex-column">
                            <h4 className="card-title text-uppercase font-weight-bold">
                              {field.name}
                            </h4>
                            <p className="card-text">
                              <ReadMore>{field.description}</ReadMore>
                            </p>
                            <p className="card-text">
                              {field.features.map((feature, index) => (
                                  <span
                                      key={index}
                                      className="d-flex align-items-center"
                                  >
                            •
                                    {iconMapping[field.featuresIcons[index]] && (
                                        <FontAwesomeIcon
                                            icon={
                                              iconMapping[field.featuresIcons[index]]
                                            }
                                            className="ms-2"
                                        />
                                    )}
                                    {feature}
                          </span>
                              ))}
                            </p>
                            <p className="card-text text-success font-weight-bold">
                              Fiyat: {field.price} TL
                            </p>
                            <p className="card-text text-success font-weight-bold">
                              {field.kapora > 0 ? `Kapora: ${field.kapora}  TL`: "Kapora ücreti alınmamaktadır"}
                            </p>
                            <p className="card-text font-weight-bold">
                              Adres: {field.address}
                            </p>
                            <div className="mt-auto">
                              <button
                                  type="button"
                                  className="btn btn-warning me-2"
                                  onClick={() =>
                                      handleReservationClick(selectedCity, field.id)
                                  }
                              >
                                Rezervasyon Yaptır
                              </button>
                              <button
                                  type="button"
                                  className="btn btn-outline-success"
                                  onClick={() => {
                                    window.open(field.directions, "_blank");
                                  }}
                              >
                                <FontAwesomeIcon
                                    icon={faMapMarkedAlt}
                                    className="me-2"
                                />
                                Yol Tarifi Al
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                  ))}
                </div>
            )}
            <PlayersList />
          </div>
        </div>
      </>
  );
}

export default Home;
