import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  CardMedia,
  Button,
  Grid,
  useMediaQuery,
  Divider,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import config from "../service/api";

function PlayersList() {
  const ApiBaseUrl = config.ApiBaseUrl;
  const ApiBaseUrlImage = config.ApiBaseUrlImage;

  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(`${ApiBaseUrl}api/get/player`);
        setPlayers(response.data);
      } catch (error) {
        console.error("Oyuncuları alırken bir hata oluştu", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayers();
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:601px) and (max-width:960px)");

  let slidesToShow = 3;
  if (isMobile) {
    slidesToShow = 1;
  } else if (isTablet) {
    slidesToShow = 2;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };

  const handleWhatsAppClick = (phoneNumber) => {
    window.open(`https://web.whatsapp.com/send?phone=${phoneNumber}`, "_blank");
  };

  return (
    <Container sx={{ paddingBottom: 10 }}>
      <Typography
        textAlign={"center"}
        variant="h3"
        gutterBottom
        style={{
          fontFamily: "Arial",

          marginTop: "200px",
          color: "white",
        }}
      >
        Hemen Oyuncu Bul
      </Typography>
      <Divider
        style={{
          backgroundColor: "white",
          marginBottom: "80px",
          height: "6px",
          width: "50%",
          margin: "0 auto",
        }}
      />

      <Slider {...settings} style={{ marginTop: "50px" }}>
        {players.map((player) => (
          <div key={player._id}>
            <Card sx={{ maxWidth: "100%", margin: "16px 10px" }}>
              <CardMedia
                component="img"
                height="345"
                image={`${ApiBaseUrlImage}${player.imageUrl}`}
                alt={`${player.name} ${player.surname}`}
              />
              <CardContent>
                <Typography variant="h6" style={{ fontSize: "1.2rem" }}>
                  {`${player.name} ${player.surname}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: "0.9rem" }}
                >
                  {`Deneyim: ${player.experience}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: "0.9rem" }}
                >
                  {`Pozisyon: ${player.position}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: "0.9rem" }}
                >
                  {`Şehir: ${player.city}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: "0.9rem" }}
                >
                  {`Yaş: ${player.age}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: "0.9rem" }}
                >
                  {`Fiyat: ${player.price} TL`}
                </Typography>
                <Grid container justifyContent="center" marginTop={2}>
                  <Grid item xs={12} sm={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="success"
                      startIcon={<WhatsAppIcon />}
                      onClick={() => handleWhatsAppClick(player.phoneNumber)}
                    >
                      Hemen İletişime Geç
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        ))}
      </Slider>
    </Container>
  );
}

export default PlayersList;
