import ReactStars from "react-rating-stars-component";
import React, { useEffect, useState } from "react";
import config from "../service/api";

export function Comments({ city, id }) {
  const ApiBaseUrl = config.ApiBaseUrl;
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [newCommentAuthor, setNewCommentAuthor] = useState("");
  const [rating, setRating] = useState(0);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    try {
      const response = await fetch(`${ApiBaseUrl}api/comments/${city}/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch comments");
      }
      const data = await response.json();
      setComments(data.comments);
      setAverageRating(data.averageRating);
    } catch (error) {
      console.error("Failed to fetch comments:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${ApiBaseUrl}api/comments/${city}/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: newCommentAuthor,
          comment: newComment,
          rating,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to add comment");
      }
      fetchComments();
      setNewComment("");
      setNewCommentAuthor("");
      setRating(0);
    } catch (error) {
      console.error("Failed to add comment:", error);
    }
  };

  const onStarClick = (nextValue) => {
    setRating(nextValue);
  };

  return (
    <div>
      <div className="container mb-5">
        <h1 className="mt-5 text-white display-5">Yorumlar</h1>
        <form onSubmit={handleSubmit} className="mb-1">
          <div className="card p-3">
            <div className="form-group mb-1 mt-2">
              <input
                type="text"
                value={newCommentAuthor}
                onChange={(e) => setNewCommentAuthor(e.target.value)}
                className="form-control"
                placeholder="İsminiz"
                required
              />
              <input
                type="text"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="form-control mt-3"
                placeholder="Yorumunuzu Yazınız"
                required
              />

              <button
                type="submit"
                className="col-md-12 btn text-white mt-1"
                style={{
                  backgroundColor: "#3C71B6",
                  borderColor: "#3C71B6",
                }}
              >
                Yorumu Gönder
              </button>
            </div>
          </div>
        </form>

        <div>
          {comments.map((comment, index) => (
            <div className="row" key={index}>
              <div className="col-md-4 mt-3">
                <div className="card">
                  <div className="card-body">
                    <p className="card-text">
                      <small className="text-muted font-italic">
                        {comment.userId}
                      </small>
                    </p>
                    <p className="card-text font-weight-bold">
                      {comment.comment}
                    </p>
                    <p className="card-text">
                      <small className="text-muted">
                        {new Date(comment.createdAt).toLocaleDateString()}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
